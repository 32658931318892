// API endpoint settingss
// const port1 = "8137";
// const port2 = "8138";
// const port3 = "8000";
// const channelsPrefix = "channels-";

const apiPrefix = "api-";
const passPrefix = "passport-";
const url = "telemed.intechs.by";
const defaultIcon = "/private-media/default_profile.png";

export const config = {
  endpoint: `https://${apiPrefix}${url}/api/v1/`,
  endpointJWT: `https://${apiPrefix}${url}/`,
  endpointPassport: `https://${passPrefix}${url}/`,
  url: `https://${apiPrefix}${url}`,
  version: "V1",
  apiName: "api",
  ws: `${apiPrefix}${url}`,
  uriImg: `https://${apiPrefix}${url}`,
  uri: `https://${apiPrefix}${url}`,
  wsType: "wss",
  defaultIcon: `https://${apiPrefix}${url}${defaultIcon}`,
};

localStorage.setItem("test", JSON.stringify(config));

const { uri, apiName, version } = config;

const endpoint = uri + "/" + apiName + "/" + version;
