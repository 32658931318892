import React, { useContext, useEffect, useState } from "react";
import { Col, Container, NavLink, Row } from "react-bootstrap";
import Select from "react-select";
import { SET_SHOW_LOADER, SHOW_DIALOG } from "../../../../AppReduser";
import { AppContext } from "../../../../App";
import { getRequest, postRequest } from "../../../../base/Requests";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  TimeItem,
  TimeLayout,
  TimePaper,
} from "../../components/doctors/booking/Components";
import moment from "moment";
import { Button } from "../../../../common/Components";
import { ReactComponent as WarningIcon } from "../../../../assets/warning.svg";
import { navigate } from "hookrouter";
import { Text } from "../UserAccount/ConsultationItem/style";
import { Checkbox, FormControlLabel, Modal } from "@material-ui/core";
import { ReactComponent as SuccessIcon } from "../../../../assets/success.svg";
import {
  DialogPaper,
  MessageContainer,
} from "../../../../common/styleDialogAdminPage";
import styled from "styled-components";
import { errorProcessing } from "../../../../module/functions/errorProcessing";

const Appointment = ({ mobile, setHeaderData }) => {
  const { dispatch, state } = useContext(AppContext);

  const [data, setData] = useState([]);
  const [timeData, setTimeData] = useState([]);
  const [date, setDate] = useState(moment().format("yyyy-MM-DD"));
  const [selected, setSelected] = useState(null);
  const [activeDate, setActiveDate] = useState(null);
  const [isVisited, setIsVisited] = useState(false);
  const [meta, setMeta] = useState(null);
  const [clinicId, setClinicId] = useState(null);
  const [specialtyId, setSpecialtyId] = useState(null);
  const [doctorId, setDoctorId] = useState(null);
  const [clinicName, setClinicName] = useState(null);
  const [clinicData, setClinicData] = useState(null);
  const [specialtyData, setSpecilatyData] = useState([]);
  const [doctorData, setDoctorData] = useState([]);
  const [isLoadingClinic, setIsLoadingClinic] = useState(true);
  const [isLoadingSpecialty, setIsLoadingSpecialty] = useState(false);
  const [isLoadingDoctor, setIsLoadingDoctor] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    dispatch({
      type: SET_SHOW_LOADER,
      payload: true,
    });

    let list = [];

    getRequest(`insurance/program/insurance_program_clinic_and_doctors/`)
      .then((res) => {
        const result = res.data;

        result?.map((name) => {
          const key = Object.keys(name);
          list.push({ value: key, label: key });
        });

        setData(result);
        setIsLoadingClinic(false);

        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
        codeDispatch("error", "Ошибка", "Нет данных");
        console.log("err", err);
      });

    setClinicData(list);

    if (!mobile) {
      return false;
    }
    setHeaderData(<h5>Записаться на прием</h5>);
  }, []);

  useEffect(() => {
    setSpecialtyId(null);
    setDoctorId(null);
    setIsVisited(false);
  }, [clinicName]);

  const codeDispatch = (type, header, title) => {
    dispatch({
      type: SHOW_DIALOG,
      payload: {
        status: true,
        type: type,
        message: (
          <>
            <h1>{header}</h1>
            <br />
            <p>{title}</p>
          </>
        ),
      },
    });
  };

  const handleSpecialty = (e) => {
    setClinicName(e);
    setIsVisited(false);
    setIsLoadingSpecialty(true);

    let list = [];
    data?.map((name) => {
      name[e]?.map((item) => {
        return list.push({ value: item, label: item });
      });
    });

    setIsLoadingSpecialty(false);
    setSpecilatyData(list);
  };

  const handleDoctor = (e) => {
    setSpecialtyId(e);
    setIsVisited(false);
    setIsLoadingDoctor(true);

    let list = [];
    getRequest(`doctor/?specialty=${e}&clinic_name=${clinicName}`)
      .then((res) => {
        const result = res.data.results;

        result?.map(
          ({
            last_name,
            middle_name,
            first_name,
            passport,
            meta,
            id,
            clinic,
          }) => {
            return list.push({
              value: id,
              label: `${last_name} ${first_name} ${middle_name}`,
              meta,
              passport,
              clinic,
            });
          }
        );
        setIsLoadingDoctor(false);
      })
      .catch((err) => {
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
        codeDispatch("error", "Ошибка", "Нет данных");
        console.log("err", err);
      });

    setDoctorData(list);
  };

  const changeDate = (e) => {
    dispatch({
      type: SET_SHOW_LOADER,
      payload: true,
    });
    const dateTime = moment(e).format("yyyy-MM-DD");
    setSelected(null);
    setDate(dateTime);

    const body = {
      clinic: clinicId,
      date: dateTime,
      specialty: specialtyId,
      meta,
    };

    postRequest("integration/clinic/filter", body)
      .then((res) => {
        //TODO
        // if (+clinicId === 2) {
        //   setTimeData(res.data.data.timetables);
        // } else if (!doctorData[0].clinic[0].work_with_api) {
        //   setTimeData(res.data.data);
        // } else {
        //   setTimeData(res.data);
        // }

        setTimeData(res.data.data);

        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
        codeDispatch("error", "Ошибка", "Нет данных");
        console.log("err", err);
      });
  };

  const handleSchedule = () => {
    dispatch({
      type: SET_SHOW_LOADER,
      payload: true,
    });

    dispatch({
      type: SHOW_DIALOG,
      payload: {
        status: false,
      },
    });

    const body = {
      clinic,
      date,
      meta: { ...metaNew, specialty: specialtyId, doctor: doctorId },
    };

    setClinicId(clinic);
    setMeta(body.meta);

    postRequest("integration/clinic/filter", body)
      .then((res) => {
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });

        console.log("clinic", clinic);
        console.log("clinicId", clinicId);

        //TODO
        // if (+clinic === 2) {
        //   setTimeData(res.data.data.timetables);
        // } else if (!doctorData[0].clinic[0].work_with_api) {
        //   setTimeData(res.data.data);
        // } else {
        //   setTimeData(res.data);
        // }

        setTimeData(res.data.data);

        setIsVisited(true);
        handleClose();
      })
      .catch((err) => {
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
        codeDispatch("error", "Ошибка", "Нет данных");
      });
  };

  const handleBook = () => {
    dispatch({
      type: SET_SHOW_LOADER,
      payload: true,
    });

    let params = {};

    //TODO
    // if (+clinic === 2) {
    //   params = {
    //     clinic,
    //     doctor_id: +doctorId,
    //     speciality_id: activeDate.speciality_id,
    //     specialty: specialtyId,
    //     syn_id: activeDate?.id,
    //     meta: {
    //       time_from: activeDate?.start_time,
    //     },
    //   };
    // } else if (+clinic === 1) {
    //   params = {
    //     clinic,
    //     doctor_id: +doctorId,
    //     syn_id: activeDate?.couponId,
    //     specialty: specialtyId,
    //     meta: {
    //       datetime_from: activeDate?.couponTimeStart,
    //     },
    //   };
    // } else if (!doctorData[0]?.clinic[0]?.work_with_api) {
    //   params = {
    //     clinic,
    //     doctor_id: doctorId,
    //     specialty: specialtyId,
    //     syn_id: activeDate?.coupon_id,
    //     meta: {
    //       datetime_from: activeDate?.datetime_from,
    //     },
    //   };
    // }

    params = {
      clinic,
      doctor_id: doctorId,
      specialty: specialtyId,
      syn_id: activeDate?.coupon_id,
      meta: {
        datetime_from: activeDate?.datetime_from,
      },
    };

    console.log("params", params);

    postRequest(`book/patient/create_order/`, params)
      .then((res) => {
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });

        navigate("/");

        dispatch({
          type: SHOW_DIALOG,
          payload: {
            type: "success",
            status: true,
            message: !mobile ? (
              <h2>Запись успешно создана!</h2>
            ) : (
              <h5>
                Информация о Вашей записи доступна на вкладке «Мой кабинет»
              </h5>
            ),
          },
        });
        changeDate();
      })
      .catch((err) => {
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });

        codeDispatch("error", "Ошибка", errorProcessing(err));
      });
  };

  const handleConfirm = () => {
    handleClose();
    dispatch({
      type: SHOW_DIALOG,
      payload: {
        status: true,
        message: (
          <>
            <WarningIcon />
            <h5 className="mt-4">
              Для доступа к телемедицинской консультации Вам необходимо провести
              первичный прием у врача очно, в медицинском учреждении
            </h5>
          </>
        ),
        customButtons: (
          <Row className="w-100">
            <Col sm={12}>
              <Button
                variant="primary"
                className="mb-3"
                onClick={() => {
                  dispatch({
                    type: SHOW_DIALOG,
                    payload: {
                      status: false,
                    },
                  });
                }}
              >
                ОК
              </Button>
            </Col>
          </Row>
        ),
      },
    });
  };

  const handleCheck = (e) => {
    setChecked(e.target.checked);
  };

  const renderCalendar = (e, index) => {
    return (
      <TimeItem
        selected={selected === index}
        key={e.datetime_from}
        onClick={() => {
          setSelected(index);
          setActiveDate(e);
        }}
      >
        {moment(e.datetime_from).format("HH:mm")}
      </TimeItem>
    );

    // Todo
    // if (clinicId === 1) {
    //   return (
    //     <TimeItem
    //       selected={selected === index}
    //       disabled={!e.isFree}
    //       key={e.couponTimeStart}
    //       onClick={() => {
    //         !e.isFree ? setSelected(null) : setSelected(index);
    //         setActiveDate(e);
    //       }}
    //     >
    //       {moment(e.couponTimeStart).format("HH:mm")}
    //     </TimeItem>
    //   );
    // } else if (clinicId === 2) {
    //   return (
    //     <TimeItem
    //       selected={selected === index}
    //       key={e.start_time + "/" + e.end_time + index}
    //       onClick={() => {
    //         setSelected(index);
    //         setActiveDate(e);
    //       }}
    //     >
    //       {moment(e.start_time).format("HH:mm")}
    //     </TimeItem>
    //   );
    // } else if (!doctorData[0].clinic[0].work_with_api) {
    //   return (
    //     <TimeItem
    //       selected={selected === index}
    //       key={e.datetime_from}
    //       onClick={() => {
    //         setSelected(index);
    //         setActiveDate(e);
    //       }}
    //     >
    //       {moment(e.datetime_from).format("HH:mm")}
    //     </TimeItem>
    //   );
    // }
  };

  const dateFrom = () => {
    return timeData.map((e, index) => (
      <React.Fragment>{renderCalendar(e, index)}</React.Fragment>
    ));
  };

  const [metaNew, setMetaNew] = useState("");
  const [clinic, setClinic] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleOpen = (meta, clinic) => {
    console.log("clinic", clinic);
    setMetaNew(meta);
    setClinic(+clinic.id);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <Container fluid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <DialogLayout>
            <DialogPaper>
              <MessageContainer>
                <div className="text-center">
                  <WarningIcon />
                </div>
                <div className="text-left">
                  <h5 className="mt-4">
                    <span style={{ color: "#f00" }}>Внимание!</span> На момент
                    проведения телемедицинской консультации Ваше последнее ОЧНОЕ
                    посещение данного специалиста должны быть не старше 6
                    месяцев. <br /> <br /> В случае предоставлении ложной
                    информации клиенту может быть отказано в оказании
                    телемедицинской услуги согласно п.3.4 Пользовательского
                    соглашения.
                    <br />
                    <br />
                    <FormControlLabel
                      control={<Checkbox onChange={handleCheck} />}
                      label="Подтвердить очную консультацию."
                    />
                  </h5>
                </div>
                <Row className="w-100">
                  <Col>
                    <Button className="mt-2 mb-3" onClick={handleConfirm}>
                      Нет
                    </Button>
                  </Col>
                  <Col sm={6}>
                    <Button
                      disabled={!checked}
                      variant="primary"
                      className="mt-2 mb-3"
                      onClick={handleSchedule}
                    >
                      Да
                    </Button>
                  </Col>
                </Row>
              </MessageContainer>
            </DialogPaper>
          </DialogLayout>
        </>
      </Modal>

      {data && data.length ? (
        <React.Fragment>
          <Row>
            <Col lg={6}>
              <h5>Выберите клинику:</h5>
              <Select
                placeholder="Выберите клинику..."
                isLoading={isLoadingClinic}
                options={clinicData}
                onChange={(e) => handleSpecialty(...e.value)}
              />
            </Col>
          </Row>
          {clinicName && (
            <React.Fragment>
              <Row>
                <Col lg={6} className="mt-3">
                  <h5>Выберите специальность врача:</h5>
                  <Select
                    key={`select_key__${clinicName}`}
                    placeholder="Выберите специальность..."
                    isLoading={isLoadingSpecialty}
                    options={specialtyData}
                    onChange={(e) => handleDoctor(e.value)}
                  />
                </Col>
              </Row>
              {specialtyId && (
                <Row>
                  <Col lg={6} className="mt-3">
                    <h5>Выберите ФИО врача:</h5>
                    <Select
                      key={`select_key__${specialtyId}`}
                      placeholder="Выберите врача..."
                      isLoading={isLoadingDoctor}
                      options={doctorData}
                      onChange={(e) => {
                        setDoctorId(e.value);
                        /*   handleAlert(e.meta, ...e.clinic);*/
                        handleOpen(e.meta, ...e.clinic);
                      }}
                    />
                  </Col>
                </Row>
              )}
            </React.Fragment>
          )}
          {clinicName && specialtyId && doctorId && isVisited && (
            <>
              <Row className="mt-4">
                <Col lg={4}>
                  <h5>Выберите время посещения</h5>
                  <Calendar
                    minDate={new Date()}
                    onChange={(e) => changeDate(e)}
                  />
                  <TimePaper>
                    <h6 className="mt-3 mb-0">Доступное время:</h6>
                    {timeData && timeData.length ? (
                      <TimeLayout className="mt-3 mb-3">
                        {dateFrom()}
                      </TimeLayout>
                    ) : (
                      <div className="mb-3">
                        <small>Нет доступных записей</small>
                      </div>
                    )}
                  </TimePaper>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={2}>
                  <Button
                    disabled={!activeDate}
                    variant="primary"
                    onClick={() => handleBook()}
                  >
                    Записаться
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </React.Fragment>
      ) : (
        <Text>Нет телемедицинской программы</Text>
      )}
    </Container>
  );
};

export default Appointment;
const SuccessIconContainer = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: pulse 2s infinite;
  border-radius: 50%;
  padding: 0;
  transform: scale(1);
  margin-bottom: 50px;
  @keyframes pulse {
    0% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 rgba(30, 180, 92, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 20px rgba(30, 180, 92, 0);
    }

    100% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 rgba(30, 180, 92, 0);
    }
  }
`;

export const DialogLayout = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorIconContainer = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: pulse-err 2s infinite;
  border-radius: 50%;
  padding: 0;
  transform: scale(1);
  margin-bottom: 50px;

  & .error {
    background-color: red;
    margin: 5px;
    width: 70px;
    height: 60px;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;

    & span {
      margin-top: -10px;
      font-weight: bold;
    }
  }

  @keyframes pulse-err {
    0% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 rgba(255, 78, 50, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 20px rgba(255, 78, 50, 0);
    }

    100% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 rgba(255, 78, 50, 0);
    }
  }
`;
