import {SHOW_DIALOG} from "../../AppReduser";
import React, {useContext} from "react";
import {AppContext} from "../../App";


const CodeDispatch = (type, header, title) => {
  const {dispatch, state} = useContext(AppContext);

  dispatch({
    type: SHOW_DIALOG,
    payload: {
      status: true,
      type: type,
      message: (
        <>
          <h1>{header}</h1>
          <br/>
          <p>{title}</p>
        </>
      ),
    },
  });
};

export default CodeDispatch