import React from "react";
import { ReactComponent as HomeIcon } from "../../../assets/home-icon.svg";
import { ReactComponent as SettingIcon } from "../../../assets/setting.svg";

export const menuClinic = [
  {
    id: 1,
    title: "Главная",
    href: "/",
    img: <HomeIcon />,
  },
  {
    id: 2,
    title: "Настройки клиники",
    href: "/setting/",
    img: <SettingIcon />,
  },
  {
    id: 3,
    title: "Запись через КЦ",
    href: "/call-center/",
    img: <SettingIcon />,
  },
  {
    id: 4,
    title: "Ручная загрузка расписания",
    href: "/add-schedule/",
    img: <SettingIcon />,
  },
  // {
  //   id: 5,
  //   title: "FAQ",
  //   href: "/faq/",
  //   img: <SettingIcon />,
  // },
];
