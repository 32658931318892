import styled from "styled-components";

export const Photo = styled.img`
  width: 100%;
  object-fit: cover;
`;

export const TimeBlock = styled.div`
  display: inline-block;
  padding: 15px;
  border-radius: 5px;
  background-color: #6574cf;
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.3;
`;

export const Card = styled.div`
  padding: 20px;
  background-color: #fff;
  color: #222b45;
  font-weight: 600;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%);
  height: ${(props) => (props.video ? "calc(100vh - 120px)" : "100%")};
`;

export const CardInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 15px;
  background-color: #eff3ff;
  color: #225ad6;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: ${(props) => (props.video ? "calc(100vh - 120px)" : "100%")};

  span {
    width: 90%;
  }
`;

export const ProfileBlock = styled.div`
  @media (max-width: 992px) {
    margin: 15px 0;
  }
`;

export const PhotoBlock = styled.span`
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 120px;
  height: 120px;
  border: 3px solid #6574cf;
`;
