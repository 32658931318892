import React, { useContext, useEffect, useState } from "react";
import { getRequest, postRequest } from "../../../../base/Requests";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import Calendar from "react-calendar";
import moment from "moment";
import { SET_SHOW_LOADER, SHOW_DIALOG } from "../../../../AppReduser";
import { AppContext } from "../../../../App";
import {
  TimeItem,
  TimeLayout,
  TimePaper,
} from "../../../user/components/doctors/booking/Components";
import { Button } from "../../../../common/Components";
import { navigate } from "hookrouter";
import { Checkbox, FormControlLabel, Modal } from "@material-ui/core";
import {
  DialogPaper,
  MessageContainer,
} from "../../../../common/styleDialogAdminPage";
import { ReactComponent as WarningIcon } from "../../../../assets/warning.svg";
import { DialogLayout } from "../../../user/pages/Appointment/Appointment";

const CallCenter = ({ mobile }) => {
  const { dispatch } = useContext(AppContext);
  const [isLoadingClinic, setIsLoadingClinic] = useState(true);
  const [patientData, setPatientData] = useState(null);
  const [clinicData, setClinicData] = useState(null);
  const [doctorData, setDoctorData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userSpeciality, setUserSpeciality] = useState(null);
  const [timeData, setTimeData] = useState(null);
  const [doctorId, setDoctorId] = useState(null);
  const [personalId, setPersonalId] = useState(null);
  const [workerId, setWorkerId] = useState(null);
  const [clinicId, setClinicId] = useState(null);
  const [selected, setSelected] = useState(null);
  const [workWithApi, setWorkWithApi] = useState(null);
  const [activeDate, setActiveDate] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [checked, setChecked] = useState(false);
  const [meta, setMeta] = useState(null);
  const [specialtyId, setSpecialtyId] = useState(null);
  const [clinicName, setClinicName] = useState(null);
  const [metaNew, setMetaNew] = useState("");
  const [isVisited, setIsVisited] = useState(false);

  const [date, setDate] = useState(moment().format("yyyy-MM-DD"));

  useEffect(() => {
    getRequest("clinic_employers/patients/").then((res) => {
      const arr = res.data?.map(
        ({ id, last_name, first_name, middle_name }) => ({
          value: id,
          label: `${last_name} ${first_name} ${middle_name}`,
        })
      );

      setPatientData(arr);
      setIsLoadingClinic(false);
    });
  }, []);

  const handlePatient = ({ value }) => {
    setUserId(value);
    getRequest(`clinic_employers/patients/${value}/get_doctor_specialty/`).then(
      (res) => {
        const arr = res.data?.map((e) => ({
          value: e,
          label: e,
        }));

        setClinicData(arr);
      }
    );
  };

  const handleSpecialty = ({ value }) => {
    postRequest(`clinic_employers/patients/get_doctor/`, {
      spec: value,
    }).then((res) => {
      const arr = res.data.map(
        ({
          last_name,
          id,
          middle_name,
          first_name,
          meta,
          clinic,
          clinic_id,
          work_with_api,
        }) => ({
          value: id,
          label: `${last_name} ${first_name} ${middle_name}`,
          meta,
          clinic,
          clinic_id,
          work_with_api,
        })
      );

      console.log("arr", arr);

      setUserSpeciality(value);
      setDoctorData(arr);
    });
  };

  const restCalendarData = (params) => {
    console.log("params", params);
    postRequest("integration/clinic/filter", params).then((res) => {
      console.log("clinicId", clinicId);
      if (+clinicId === 1) {
        setTimeData(res.data);
      } else {
        setTimeData(res?.data?.data?.timetables);
      }
    });
  };

  const handleDoctor = (e) => {
    setDoctorId(e.value);
    setPersonalId(e.meta.personal_id);
    setClinicId(e.clinic_id);
    setWorkerId(e.meta.worker_id);
    setWorkWithApi(e.work_with_api);

    console.log("call center handleDoctor", e);

    const params = {
      date,
      clinic: e.clinic_id,
      meta: {
        doctor: e.value,
        personal_id: e.meta.personal_id,
        specialty: userSpeciality,
        worker_id: e.meta.worker_id,
      },
    };

    setOpen(true);
  };

  const changeDate = (e) => {
    const date = moment(e).format("YYYY-MM-DD");
    setSelected(null);

    let params = "";

    /*
    todo проврка тк разные структуры клиник
    if (clinicId === 1) {
      console.log("Выберите врача:");
      params = {
        clinic: clinicId,
        date,
        meta: {
          specialty: userSpeciality,
          personal_id: personalId,
        },
      };
    } else if (clinicId === 2) {
      params = {
        clinic: clinicId,
        date,
        meta: {
          specialty: userSpeciality,
          worker_id: workerId,
        },
      };
    } else if (!workWithApi) {
      params = {
        clinic: clinicId,
        date,
        meta: {
          specialty: userSpeciality,
          doctor: doctorId,
        },
      };
    }*/

    params = {
      clinic: clinicId,
      date,
      meta: {
        specialty: userSpeciality,
        doctor: doctorId,
      },
    };

    postRequest(`integration/clinic/filter`, params)
      .then((res) => {

        //TODO
        // if (+clinicId === 2) {
        //   setTimeData(res.data.data.timetables);
        // } else if (!workWithApi) {
        //   setTimeData(res.data.data);
        // } else {
        //   setTimeData(res.data);
        // }

        setTimeData(res.data.data);

        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
      });
  };

  const handleBook = () => {
    dispatch({
      type: SET_SHOW_LOADER,
      payload: true,
    });

    let params = "";

    /*  TODO vremenno proverka dl9 raznih stryctyr clinic
    if (clinicId === 1) {

      params = {
        clinic: clinicId,
        doctor_id: doctorId,
        specialty: userSpeciality,
        syn_id: activeDate?.couponId,
        meta: {
          patient_id: userId,
          datetime_from: activeDate?.couponTimeStart,
        },
      };
    } else if (clinicId === 2) {
      params = {
        clinic: clinicId,
        doctor_id: doctorId,
        specialty: userSpeciality,
        syn_id: activeDate?.id,
        meta: {
          patient_id: userId,
          time_from: activeDate?.start_time,
        },
      };
    } else if (!workWithApi) {
      console.log("activeDate", activeDate);
      params = {
        clinic: clinicId,
        doctor_id: doctorId,
        specialty: userSpeciality,
        syn_id: activeDate?.coupon_id,
        meta: {
          patient_id: userId,
          datetime_from: activeDate?.datetime_from,
        },
      };
    }*/

    params = {
      clinic: clinicId,
      doctor_id: doctorId,
      specialty: userSpeciality,
      syn_id: activeDate?.coupon_id,
      meta: {
        patient_id: userId,
        datetime_from: activeDate?.datetime_from,
      },
    };
    /*
        if (!workWithApi) {
          /!*Бальнео*!/
          params = {
            clinic: clinicId,
            doctor_id: doctorId,
            specialty: userSpeciality,
            syn_id: activeDate?.couponId,
            meta: {
              patient_id: userId,
              datetime_from: activeDate?.couponTimeStart,
            },
          };
        } else {
          console.log("activeDate", activeDate);
          params = {
            clinic: clinicId,
            doctor_id: doctorId,
            syn_id: activeDate?.id,
            specialty: userSpeciality,
            meta: {
              worker_id: workerId,
              patient_id: userId,
              time_from: activeDate?.start_time,
            },
          };
        }*/

    postRequest(`book/patient/create_order/`, params)
      .then(() => {
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });

        dispatch({
          type: SHOW_DIALOG,
          payload: {
            type: "success",
            status: true,
            message: !mobile ? (
              <h2>Запись успешно создана!</h2>
            ) : (
              <h5>
                Информация о Вашей записи доступна на вкладке «Мой кабинет»
              </h5>
            ),
          },
        });
        changeDate();

        navigate("/");
      })
      .catch((err) => {
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
        const error = err.data;
        codeDispatch(
          "error",
          "Ошибка",
          error ? error.text : "Произошла ошибка"
        );
      });
  };

  const codeDispatch = (type, header, title) => {
    dispatch({
      type: SHOW_DIALOG,
      payload: {
        status: true,
        type: type,
        message: (
          <>
            <h1>{header}</h1>
            <br />
            <p>{title}</p>
          </>
        ),
      },
    });
  };

  useEffect(() => {
    setSpecialtyId(null);
    setDoctorId(null);
    setIsVisited(false);
  }, [clinicName]);

  const renderCalendar = (e, index) => {
    return (
      <TimeItem
        selected={selected === index}
        key={e.datetime_from}
        onClick={() => {
          setSelected(index);
          setActiveDate(e);
        }}
      >
        {moment(e.datetime_from).format("HH:mm")}
      </TimeItem>
    );

    /* TODO
  if (clinicId === 1) {
      console.log("1");
      return (
        <TimeItem
          selected={selected === index}
          disabled={!e.isFree}
          key={e.couponTimeStart}
          onClick={() => {
            !e.isFree ? setSelected(null) : setSelected(index);
            setActiveDate(e);
          }}
        >
          {moment(e.couponTimeStart).format("HH:mm")}
        </TimeItem>
      );
    } else if (clinicId === 2) {
      console.log("2");
      return (
        <TimeItem
          selected={selected === index}
          key={e.start_time + "/" + e.end_time + index}
          onClick={() => {
            setSelected(index);
            setActiveDate(e);
          }}
        >
          {moment(e.start_time).format("HH:mm")}
        </TimeItem>
      );
    } else if (!workWithApi) {
      console.log("3");
      return (
        <TimeItem
          selected={selected === index}
          key={e.datetime_from}
          onClick={() => {
            setSelected(index);
            setActiveDate(e);
          }}
        >
          {moment(e.datetime_from).format("HH:mm")}
        </TimeItem>
      );
    }*/
  };

  const dateFrom = () => {
    return timeData.map((e, index) => renderCalendar(e, index));
  };

  const handleCheck = (e) => {
    setChecked(e.target.checked);
  };

  const handleConfirm = () => {
    handleClose();
    dispatch({
      type: SHOW_DIALOG,
      payload: {
        status: true,
        message: (
          <>
            <WarningIcon />
            <h5 className="mt-4">
              Для доступа к телемедицинской консультации Вам необходимо провести
              первичный прием у врача очно, в медицинском учреждении
            </h5>
          </>
        ),
        customButtons: (
          <Row className="w-100">
            <Col sm={12}>
              <Button
                variant="primary"
                className="mb-3"
                onClick={() => {
                  dispatch({
                    type: SHOW_DIALOG,
                    payload: {
                      status: false,
                    },
                  });
                }}
              >
                ОК
              </Button>
            </Col>
          </Row>
        ),
      },
    });
  };

  const handleSchedule = () => {
    dispatch({
      type: SET_SHOW_LOADER,
      payload: true,
    });

    dispatch({
      type: SHOW_DIALOG,
      payload: {
        status: false,
      },
    });
    let body = "";
    //Условия нужны потому что у клиник разная структура - очень важен порядок !!!
    // if (clinicId === 1) {
    //   body = {
    //     clinic: clinicId,
    //     date: moment().format("YYYY-MM-DD"),
    //     meta: {
    //       specialty: userSpeciality,
    //       personal_id: personalId,
    //     },
    //   };
    // } else if (clinicId === 2) {
    //   body = {
    //     clinic: clinicId,
    //     date: moment().format("YYYY-MM-DD"),
    //     meta: {
    //       specialty: userSpeciality,
    //       worker_id: workerId,
    //     },
    //   };
    // } else {
    //   body = {
    //     clinic: clinicId,
    //     date: moment().format("YYYY-MM-DD"),
    //     meta: {
    //       specialty: userSpeciality,
    //       doctor: doctorId,
    //     },
    //   };
    // }

    body = {
      clinic: clinicId,
      date: moment().format("YYYY-MM-DD"),
      meta: {
        specialty: userSpeciality,
        doctor: doctorId,
      },
    };

    setClinicId(clinicId);
    setMeta(body.meta);

    postRequest("integration/clinic/filter", body)
      .then((res) => {
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });

        //TODO
        // if (+clinicId === 2) {
        //   setTimeData(res.data.data.timetables);
        // } else if (!workWithApi) {
        //   setTimeData(res.data.data);
        // } else {
        //   setTimeData(res.data);
        // }

        setTimeData(res.data.data);

        setIsVisited(true);
        handleClose();
      })
      .catch((err) => {
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
        codeDispatch("error", "Ошибка", "Нет данных");
      });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <DialogLayout>
            <DialogPaper>
              <MessageContainer>
                <div className="text-center">
                  <WarningIcon />
                </div>
                <div className="text-left">
                  <h5 className="mt-4">
                    <span style={{ color: "#f00" }}>Внимание!</span> На момент
                    проведения телемедицинской консультации Ваше последнее ОЧНОЕ
                    посещение данного специалиста должны быть не старше 6
                    месяцев. <br /> <br /> В случае предоставлении ложной
                    информации клиенту может быть отказано в оказании
                    телемедицинской услуги согласно п.3.4 Пользовательского
                    соглашения.
                    <br />
                    <br />
                    <FormControlLabel
                      control={<Checkbox onChange={handleCheck} />}
                      label="Подтвердить очную консультацию."
                    />
                  </h5>
                </div>
                <Row className="w-100">
                  <Col>
                    <Button className="mt-2 mb-3" onClick={handleConfirm}>
                      Нет
                    </Button>
                  </Col>
                  <Col sm={6}>
                    <Button
                      disabled={!checked}
                      variant="primary"
                      className="mt-2 mb-3"
                      onClick={handleSchedule}
                    >
                      Да
                    </Button>
                  </Col>
                </Row>
              </MessageContainer>
            </DialogPaper>
          </DialogLayout>
        </>
      </Modal>

      <Row>
        <Col lg={6}>
          <h5>Выберите пациента:</h5>
          <Select
            placeholder="Выберите пациента"
            isLoading={isLoadingClinic}
            options={patientData}
            onChange={handlePatient}
          />
        </Col>
      </Row>
      <br />
      {clinicData && (
        <Row>
          <Col lg={6}>
            <h5>Выберите специальность:</h5>
            <Select
              placeholder="Выберите специальность"
              isLoading={isLoadingClinic}
              options={clinicData}
              onChange={handleSpecialty}
            />
          </Col>
        </Row>
      )}
      <br />
      {doctorData && doctorData && (
        <Row>
          <Col lg={6}>
            <h5>Выберите врача:</h5>
            <Select
              placeholder="Выберите врача"
              isLoading={isLoadingClinic}
              options={doctorData}
              onChange={handleDoctor}
            />
          </Col>
        </Row>
      )}
      <br />

      {clinicData && doctorData && timeData && (
        <>
          <Row className="mt-4 mb-4">
            <Col lg={4}>
              <h5>Выберите дату консультации</h5>
              <Calendar minDate={new Date()} onChange={changeDate} />
              <TimePaper>
                <h6 className="mt-3 mb-0">Доступное время:</h6>
                {timeData.length ? (
                  <TimeLayout className="mt-3 mb-3">{dateFrom()}</TimeLayout>
                ) : (
                  <div className="mb-3">
                    <small>Нет доступных записей</small>
                  </div>
                )}
              </TimePaper>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col lg={2}>
              <Button
                disabled={!activeDate}
                variant="primary"
                onClick={handleBook}
              >
                Записаться
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default CallCenter;
