import styled from "styled-components";

export const Close = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
`;
export const DialogLayout = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: ${(props) => (props.status ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;
export const DialogPaper = styled.div`
  position: relative;
  min-height: 200px;
  max-width: 600px;
  width: calc(90vw - 20px);
  padding: 50px 20px 10px 20px;
  border-radius: 8px;
  background-color: white;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  & button {
    float: right;
    width: 100%;
  }
`;
export const MessageContainer = styled.div`
  display: inline-block;
  width: 100%;
  & h1 {
    margin-bottom: 0;
  }
  margin-bottom: 30px;

  &::-webkit-scrollbar {
    width: 0;
  }
  & {
    -ms-overflow-style: none;
  }

  & {
    overflow: -moz-scrollbars-none;
  }
`;
