import styled from "styled-components";
import { CardDescription } from "../../../../common/Components";
import { Ul } from "../../../user/components/appointments/appointmentsDetails/StyleAppointmentDetails";

export const Profile = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  @media (max-width: 780px) {
    margin: 0 0 10px;
    &:last-child {
      margin: 0;
    }
  }
`;
export const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #7b7f8d;
`;

export const HeaderProfile = styled.div`
  margin: 40px 0 0 0;
`;

export const DescriptionCard = styled(CardDescription)`
  font-size: 16px;
  margin: 0 5px 0 0;
`;

export const DescriptionError = styled(CardDescription)`
  font-size: 16px;
  font-weight: 400;
  color: #ff0000;
`;

export const TitleCard = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

export const TitleHref = styled.a`
  font-size: 16px;
  font-weight: 600;
`;

export const SpanActive = styled(TitleCard)`
  color: ${(props) => (props.active ? "#28a745" : "#000")};
`;

export const SpanPolicy = styled(TitleCard)`
  color: #6574cf;
  cursor: pointer;
  text-decoration: underline;

  :hover {
    opacity: 0.6;
  }
`;

export const DetailsInfo = styled.div`
  margin-top: 5px;
  border-top: 1px solid #edf1f7;

  & table {
    font-size: 15px;
    font-weight: 600;

    thead th {
      color: #7b7f8d;
    }
  }

  .row {
    margin-right: 0 !important;
  }

  @media (max-width: 320px) {
    table {
      font-size: 12px;
    }
  }
`;

export const InfoBlock = styled(Ul)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  align-items: center;
  @media (max-width: 1660px) {
    grid-template-columns: 1fr;
  }

  li {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    margin: 0 0 12px;
    padding: 0;
    border: none;
    @media (max-width: 385px) {
      //margin: 0 0 20px;
      &:last-child {
        margin: 0;
      }
    }

    & svg {
      width: 22px;
      vertical-align: sub;

      & path {
        fill: #8f9bb3;
      }
    }
  }
`;

export const Block = styled.div`
  width: 50%;
  margin: auto;
  min-height: calc(100vh - 170px);
  padding: 20px;
  border-radius: 6px;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;
export const TabContent = styled.div`
  border-radius: 10px;
  border: 1px solid #ccc;
  max-height: 280px;
  overflow: auto;
`;
