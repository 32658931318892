import styled from "styled-components";
import { Button } from "../../../../common/Components";
import { ReactComponent as Close } from "../../../../assets/close-admin.svg";
import { HeaderLayout } from "../../Components";

export const ButtonNew = styled(Button)`
  display: inline-block;
`;

export const NewClose = styled(Close)`
  #Background-2 {
    fill: #6574cf;
  }
`;

export const ButtonClose = styled.span`
  position: absolute;
  right: 5px;
  top: -5px;
  cursor: pointer;

  svg {
    width: 40px;
  }
`;

export const Mobil = styled.div`
  position: fixed;
  width: 50%;
  height: 100%;
  padding: 50px 20px;
  left: 0;
  background-color: #f6f6f6;
  @media (max-width: 767px) {
    width: 50%;
    .mob {
      display: inline-block;
    }
  }
  @media (max-width: 460px) {
    width: 100%;
    .mob {
      display: inline-block;
    }
  }
`;

export const MenuItem = styled.div`
  font-weight: initial;
  color: ${(props) => (props.active ? "#6574cf" : "black")};
  padding: 0 10px;
  cursor: pointer;
  transition: 0.3s;

  & a {
    margin-right: 5px;
  }

  &:hover {
    color: #6574cf;

    svg path {
      fill: #6574cf;
    }
  }

  svg {
    vertical-align: middle;

    path {
      fill: ${(props) => (props.active ? "#6574cf" : "#92929D")};
    }
  }

  @media (max-width: 1001px) {
    padding: 0 10px;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin: 0 0 20px;
    font-size: 20px;
  }
  @media (max-width: 460px) {
    width: 100%;
    margin: 0 0 20px;
    font-size: 20px;
  }
`;

export const Menu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1001px) {
    padding: 0 5px;
    font-size: 12px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

export const HeaderLayoutAdmin = styled(HeaderLayout)`
  display: inline-block;
  z-index: 99;
`;

export const MobilMenu = styled.div`
  span {
    display: none;
  }

  @media (max-width: 767px) {
    & span {
      display: flex;
      align-items: center;
      color: #979797;
      cursor: pointer;

      &:hover {
        transition: 0.3s;
        opacity: 0.8;
      }

      & span {
        margin-left: 5px;
      }
    }
  }
`;

export const SpanLogo = styled.span`
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;
