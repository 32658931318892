import React from 'react';
import styled from 'styled-components';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import LoaderSpinner from 'react-loader-spinner';

const Loader = (props) => {
  return (
    <LoaderLayout>
      <LoaderSpinner
        type="TailSpin"
        color="#6574cf"
        height={50}
        width={50}
        secondaryColor='gray'
      />
    </LoaderLayout>
  );
};

export default Loader;

const LoaderLayout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 100;
`;
