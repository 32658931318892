import { Item } from "../../../../insurance-admin/pages/InsuranceProgram/InsuranceProgramItem/style";
import { Col, Row } from "react-bootstrap";
import {
  DescriptionCard,
  Profile,
  TitleCard,
} from "../../../../insurance-admin/pages/Patient/stylePatient";
import moment from "moment";
import React from "react";
import { Button } from "../../../../../common/Components";
import { NewCounter } from "../../UserAccount/ConsultationItem/style";
import { navigate } from "hookrouter";

const CardFielsaDoctor = ({ e, action, role }) => {
  const handleHref = ({ id }) => {
    if (role === "clinic_admin") return false;

    if (action) {
      navigate(`/consultation/${id}`);
    } else navigate(`/consultation/past/${id}`);
  };

  const newDate = () => {
    return new Date() >= new Date(e?.datetime_from);
  };

  return (
    <Item clickOn={role === "clinic_admin"} onClick={() => handleHref(e)}>
      <Row>
        <Col lg={12} className="d-flex justify-content-start align-self-center">
          <div>
            {action && e.count > 0 && <NewCounter>{e.count}</NewCounter>}
            <Profile>
              <DescriptionCard>Дата:</DescriptionCard>
              <TitleCard>
                {e?.datetime_from
                  ? moment(e?.datetime_from).format("DD.MM.YYYY")
                  : "-"}
              </TitleCard>
            </Profile>
            <Profile>
              <DescriptionCard>Время:</DescriptionCard>
              <TitleCard>
                {e?.datetime_from
                  ? moment(e?.datetime_from).format("HH:mm")
                  : "-"}
              </TitleCard>
            </Profile>
            <Profile>
              <DescriptionCard>ФИО:</DescriptionCard>
              <TitleCard>
                {e?.patient?.last_name || "-"} {e?.patient?.first_name || "-"}{" "}
                {e?.patient?.middle_name || "-"}
              </TitleCard>
            </Profile>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <React.Fragment>
          <Col sm={12} className="mb-3 mb-lg-0">
            {role === "clinic_admin" ? (
              <Button
                variant="danger"
                className="mt-3 mb-4 w-100"
                disabled={newDate()}
                onClick={() => navigate(`/consultation/reason/${e.id}`)}
              >
                Отменить консультацию
              </Button>
            ) : (
              <Button variant="cancel" className="w-100 fz10">
                Подробнее
              </Button>
            )}
          </Col>
        </React.Fragment>
      </Row>
    </Item>
  );
};

export default CardFielsaDoctor;
