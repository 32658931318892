import { navigate } from "hookrouter";
import React, { useContext, useState } from "react";
import { AppContext } from "../../../../App";
import { ReactComponent as Logo } from "../../../../assets/logo.svg";
import { ReactComponent as Exit } from "../../../../assets/exit.svg";
import AdminUser from "../../module/AdminUser/AdminUser";
import { Col, Row } from "react-bootstrap";
import {
  AUTHORIZE,
  SET_ACCESS_TOKEN,
  SHOW_DIALOG,
} from "../../../../AppReduser";
import { Button } from "../../../../common/Components";
import { postRequest } from "../../../../base/Requests";

import { ReactComponent as MenuIco } from "../../../../assets/menu.svg";
import {
  ButtonClose,
  ButtonNew,
  HeaderLayoutAdmin,
  Menu,
  MenuItem,
  Mobil,
  MobilMenu,
  NewClose,
  SpanLogo,
} from "./HeaderStyle";
import { menuList } from "./function/menuList";
import { deleteCookie } from "../../../../module/deleteCookie/deleteCookie";

const Header = ({ menu = [] }) => {
  const { state, dispatch } = useContext(AppContext);

  const [menuMob, setMenuMob] = useState(false);

  const handleExit = () => {
    dispatch({
      type: SHOW_DIALOG,
      payload: {
        status: true,
        message: (
          <>
            <h1>Выход из учетной записи</h1>
            <p>Вы действительно желаете выйти со своей учетной записи?</p>
          </>
        ),
        customButtons: (
          <Row className="w-100">
            <Col sm={6}>
              <Button
                onClick={() => {
                  /*TODO*/
                  postRequest("user/log_out/", { name: 1 })
                    .then((res) => {
                      localStorage.removeItem("access_token");
                      localStorage.removeItem("role");
                      dispatch({
                        type: AUTHORIZE,
                        payload: null,
                      });
                      dispatch({
                        type: SET_ACCESS_TOKEN,
                        payload: null,
                      });
                      dispatch({
                        type: SHOW_DIALOG,
                        payload: {
                          status: false,
                        },
                      });
                      deleteCookie();
                      navigate("/sign-in/", true);
                    })
                    .catch((err) => {
                      console.log("err", err);
                      dispatch({
                        type: SHOW_DIALOG,
                        payload: {
                          status: true,
                          type: "error",
                          message: (
                            <>
                              <h1>Ошибка!</h1>
                              <p>Вы остались в системе</p>
                            </>
                          ),
                        },
                      });
                    });
                }}
                variant="primary"
                style={{ marginBottom: "15px" }}
              >
                Да
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  dispatch({
                    type: SHOW_DIALOG,
                    payload: {
                      status: false,
                    },
                  });
                }}
              >
                Отмена
              </Button>
            </Col>
            <br />
          </Row>
        ),
      },
    });
  };

  return (
    <HeaderLayoutAdmin>
      <Row className="h-100">
        <Col md={3} className="align-self-center d-none d-md-flex">
          <SpanLogo onClick={() => navigate("/", true)}>
            <Logo />
          </SpanLogo>
        </Col>
        <Col
          xs={6}
          md={7}
          className="align-self-center text-left text-md-center"
        >
          <MobilMenu>
            <span onClick={() => setMenuMob(true)}>
              <MenuIco />
              <span>Меню</span>
            </span>
            <Menu>{menuList(menu, setMenuMob)}</Menu>
          </MobilMenu>
        </Col>
        <Col xs={6} md={2} className="align-self-center text-right">
          {state?.role !== "clinic_admin" &&
          state?.role !== "insurance_admin" ? (
            <>
              <AdminUser />
            </>
          ) : (
            <ButtonNew variant="danger" onClick={handleExit}>
              Выйти
            </ButtonNew>
          )}
        </Col>
      </Row>
      {menuMob && (
        <Mobil>
          <ButtonClose onClick={() => setMenuMob(false)}>
            <NewClose />
          </ButtonClose>

          <p className="text-center mt-4 mb-5">
            <Logo />
          </p>

          <Menu className="mob">
            {menuList(menu, setMenuMob)}
            {state?.role === "clinic_admin" && (
              <>
                <hr />
                <MenuItem onClick={handleExit}>
                  <Exit /> Выйти
                </MenuItem>
              </>
            )}
          </Menu>
        </Mobil>
      )}
    </HeaderLayoutAdmin>
  );
};

export default Header;
