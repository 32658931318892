import "./App.css";
import "./pages/admin/pages/doctors/style.css";
import React, { createContext, useEffect, useReducer, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { appReducer, initialState, MENU, USER_DATA } from "./AppReduser";
import { MuiThemeProvider } from "material-ui/styles";
import "react-chat-elements/dist/main.css";
import { Helmet } from "react-helmet";
import "moment/locale/ru";
import { getRequestFetch } from "./base/Requests";
import Loader from "./common/Loader";
import ClinicAdmin from "./pages/clinic-admin/ClinicAdmin";

export const AppContext = createContext({});

const Authorize = React.lazy(() =>
  import("./pages/account/Authorize/Authorize")
);
const User = React.lazy(() => import("./pages/user/pages/user/User"));
const Dialog = React.lazy(() => import("./common/Dialog"));
const Payments = React.lazy(() => import("./common/Payments/Payments"));
const Admin = React.lazy(() => import("./pages/admin/Admin"));
const DoctorAdmin = React.lazy(() =>
  import("./pages/doctor-admin/DoctorAdmin")
);
const Meet = React.lazy(() => import("./common/Meet"));
const AdminDialog = React.lazy(() => import("./common/AdminDialog"));
const InsuranceAdmin = React.lazy(() =>
  import("./pages/insurance-admin/InsuranceAdmin")
);
const DialogAdminPage = React.lazy(() => import("./common/DialogAdminPage"));

const App = () => {
  // context
  const [state, dispatch] = useReducer(appReducer, initialState);
  let value = { state, dispatch };
  const { inCall, loading } = state;
  const path = window.location.pathname;

  useEffect(() => {
    if (path === "/sign-in/") return; //чтобы небыло цикличной перезагрузки страницы

    /* if (!state.menu) {
      navigate("/create-account", true);
    }*/
    if (localStorage.getItem("role") === "doctor") {
      getRequestFetch("doctor/me/").then((res) => {
        dispatch({
          type: USER_DATA,
          payload: res?.data || [],
        });
      });
    } else if (localStorage.getItem("role") === "patient") {
      getRequestFetch("patient/about_me/").then((res) => {
        if (res?.access) {
          localStorage.setItem("menu", res.access);
        }
        dispatch({
          type: MENU,
          payload: res?.access,
        });
        dispatch({
          type: USER_DATA,
          payload: res,
        });
      });
    }
  }, []);

  const switchRouter = (role) => {
    switch (role) {
      case "clinic_admin":
        return <ClinicAdmin />;
      case "doctor":
        return <DoctorAdmin />;
      case "patient":
        return <User active={JSON.parse(localStorage.getItem("menu"))} />;
      case "insurance_admin":
        return <InsuranceAdmin />;
      default:
        return <Authorize />;
    }
  };

  return (
    <MuiThemeProvider theme={null}>
      <Suspense fallback={<Loader />}>
        <AppContext.Provider value={value}>
          {inCall && <Meet />}
          <Helmet>
            <meta name="apple-mobile-web-app-capable" content="yes" />

            <meta
              name="apple-mobile-web-app-status-bar-style"
              content="black-transluent"
            />
            <title>Teledoctor</title>
          </Helmet>
          {state.showDialog.status ? <Dialog /> : null}
          {state.payment.status ? <Payments /> : null}
          {state.showAdminDialog ? <AdminDialog /> : null}
          {state.showDialogAdmin ? <DialogAdminPage /> : null}
          {loading ? <Loader /> : null}
          <div className="app">{switchRouter(state.role)}</div>
        </AppContext.Provider>
      </Suspense>
    </MuiThemeProvider>
  );
};

export default App;
