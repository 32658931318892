import styled from "styled-components";
import { Button } from "../../../../common/Components";

export const Title = styled.h6`
  font-size: 20px;
  color: #6574cf;
`;

export const NewButton = styled(Button)`
  display: inline-block;

  &:disabled {
    color: #afafaf;
    background-color: #e7e7e7;
  }
`;

export const Span = styled.span`
  font-weight: 600;
  font-size: 16px;
  color: #6574cf;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const FormEdit = styled.form`
  input {
    font-size: 14px;
    color: #222b45;
    font-weight: 700;
  }
`;

export const ButtonAdd = styled.span`
  font-weight: 700;
  font-size: 15px;
  color: #6574cf;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 0;
`;

export const ContainerBlock = styled.div`
  .cursor-pointer {
    cursor: pointer;
  }
`;
