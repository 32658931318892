import styled from "styled-components";

export const CardInfo = styled.div`
  padding: 15px;
  background-color: ${(props) =>
    props.background ? props.background : "white"};
  color: #222b45;
  font-weight: 600;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%);
  min-height: 311px;
  max-height: 311px;
  overflow-y: auto;
  overflow-x: auto;
}
`;

export const ProgramList = styled.div`
  font-weight: 600;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: ${(props) => (props.checked ? "#CCCCCC4C" : "#fff")};

  :hover {
    background-color: rgba(204, 204, 204, 0.3);
  }
`;

export const CardLabel = styled.label`
  width: 100%;
  cursor: pointer;
`;

export const SpanTitle = styled.span`
  color: #6574cf;
`;

export const Main = styled.div`
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom: 1px solid #ccc;

  div {
    overflow-x: hidden !important;
  }
`;

export const Text = styled.p`
  text-align: center;
  font-size: 30px;
  color: #8f9bb3;
`;

export const Clear = styled.span`
  font-size: 15px;
  margin-left: 5px;
  color: #f00;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;
