import styled from "styled-components";
import { ReactComponent as ArrowIcon } from "../../../../assets/back-arrow.svg";

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  max-height: 100px;
  resize: none;
  padding: 10px;
  border-radius: 8px;
  outline: none;

  :focus {
    border: ${(props) => `1px solid ${props.error ? "#ff0000" : "#6574cf"}`};
  }
`;

export const Arrow = styled(ArrowIcon)`
  cursor: pointer;

  path {
    fill: #6574cf;
  }

  :hover {
    opacity: 0.8;
  }
`;
