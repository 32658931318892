import styled from "styled-components";
import {ReactComponent as Notification} from "../../../../assets/bell.svg";
import {ReactComponent as Arrow} from "../../../../assets/arrow.svg";

export const DropMenu = styled.ul`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: absolute;
  content: '';
  right: 14px;
  top: 46px;
  width: 170px;
  background-color: #fff;
  color: #000;
  border-radius: 5px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 18%);
  padding: 10px;

}

& li {
  padding: 5px;
  text-align: center;
  list-style-type: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  border-bottom: 1px solid #edf1f7;
}

& :last-child {
  border-bottom: none;
}

& :hover {
  background-color: #f8f9fa;
}
`;

export const NotificationIcon = styled(Notification)`
  path {
    fill: #000;
  }
`;

export const UserLogo = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 46px;
  height: 46px;
  padding: 4px;
  margin: 0 10px 0 0;
  background-color: #6574cf;
  border-radius: 50%;
  overflow: hidden;
`;

export const Color = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 50%;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
`;

export const Title = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const WrapperUser = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  flex-wrap: wrap;

  &:hover {
    background-color: #f8f9fa;
  }
`;

export const OpenOptions = styled(Arrow)`
  margin-left: 10px;
  width: 12px;
  height: 12px;
  transform: rotate(90deg);
`;

export const Name = styled.h5`
  margin: 0 0 2px;
  font-weight: 600;
  font-size: 15px;
  line-height: 10px;
  color: #171b1e;
  @media (max-width: 848px) {
    font-size: 12px;
  }
`;

export const WrapperNotification = styled.div`
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: 0 11px 0 22px;

  span {
    position: absolute;
    width: 12px;
    height: 12px;
    top: -3px;
    right: -3px;
    background-color: #fc5a5a;
    border-radius: 50%;
  }
`;

export const Avatar = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;
