import styled from "styled-components";
import { Button } from "../../../../common/Components";

export const ButtonNew = styled(Button)`
  display: inline-block;
  background-color: #6574cf;
  border-color: #6574cf;
  margin-left: 14px;
  @media (max-width: 470px) {
    margin-left: 0;
  }
`;

export const ButtonSave = styled.button`
  padding: 12px 20px;
  border-radius: 6px;
  float: none !important;
  display: inline-block;
  width: auto !important;
  font-size: 14px;
  color: #f6f8f9;
  font-weight: 700;
  background-color: #6574cf;
  border: 1px solid #6574cf;
  text-transform: uppercase;
  ${(props) => (props.disabled ? "background : #ccc" : "background: #6574cf")};
  border: ${(props) => `1px solid ${props.disabled ? "#ccc" : "#6574cf"}`};

  &:hover {
    color: #6574cf;
    border: 1px solid rgba(101, 116, 207, 0.29);
    background-color: rgba(101, 116, 207, 0.29);
  }
`;

export const ButtonCancel = styled(ButtonSave)`
  color: #6574cf;
  background-color: transparent;
`;

export const Wrapp = styled.div`
  width: 100%;
  text-align: left;

  label {
    font-size: 15px;
    color: #8f9bb3;
    font-weight: 600;
  }

  .form-control {
    height: 45px;
    border-color: #dcdce0;
  }

  input {
    font-size: 14px;
    color: #222b45;
    font-weight: 700;
  }

  h1 {
    margin: 0 0 10px;
    font-size: 22px;
    color: #6574cf;
  }
`;

export const ButtonPopup = styled.button`
  margin: 20px 0 0;
  width: 100%;
  padding: 30px;
  background: #f7f9fc;
  border-radius: 8px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  border: none;
  color: #222b45;

  &:hover {
    background-color: #e6ecfc;
  }
`;

export const Popular = styled.div`
  button {
    margin: 0 15px 0 0;
    padding: 4px 8px;
    font-weight: 600;
    font-size: 13px;
    color: #6574cf;
    background: #eeeff7;
    border-radius: 4px;
    border: none;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      color: #fff;
      background: #6574cf;
    }
  }

  @media (max-width: 480px) {
    display: none;
  }
`;

export const Img = styled.div`
  display: inline-block;
  width: 58px;
  height: 58px;
  background-image: url(${(props) => props.url});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid #999;
  border-radius: 50%;
`;

export const TableButton = styled.button`
  display: inline-block;
  min-width: 155px;
  margin: 0 0 10px;
  padding: 8px 22px;
  color: ${(props) => (props.variant === "error" ? "#fff" : "#222b45")};
  font-size: 12px;
  font-weight: 700;
  border: none;
  opacity: 1;
  background-color: ${(props) =>
    props.variant === "error" ? "#f00" : "#C9E7E5"};
  border-radius: 4px;

  &:hover {
    opacity: 0.7;
  }

  &:last-child {
    margin: 0;
  }
`;

export const Name = styled.span`
  font-weight: 700;
  font-size: ${(props) => `${props.mobile ? "16" : "18"}px`};
`;

export const Span = styled.span`
  color: ${(props) => (props.color ? props.color : "#000")};
  font-weight: 700;
  font-size: 16px;
`;

export const NameClient = styled(Name)`
  color: #212f87;
  cursor: pointer;

  :hover {
    text-decoration: underline;
    transition: 0.2s;
    text-shadow: -0.02em 0 0 #6574cf;
  }
`;

export const SpanClient = styled(Span)`
  color: #212f87;
  cursor: pointer;

  :hover {
    text-decoration: underline;
    transition: 0.2s;
    text-shadow: -0.02em 0 0 #6574cf;
  }
`;

export const TitleTable = styled.h6`
  font-size: 16px;
  font-weight: 400;
  color: #8f9bb3;
`;

export const Doctor = styled.div`
  margin: 20px 0;
  padding: 20px;
  background-color: ${(props) => (props.checked ? "#6574cf" : "white")};
  border-radius: 5px;
  font-size: 16px;
  color: #222b45;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%);

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.hover {
    :hover {
      color: #fff;
      background-color: #6574cf;
      cursor: pointer;

      h4,
      h6,
      span {
        color: #fff;
      }
    }
  }
`;

export const DoctorsListLayout = styled.div`
  margin: 20px;
`;

export const AddDoctorLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`;

export const Gender = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
`;

export const Title = styled.div`
  text-transform: uppercase;
  color: #8992a3;
  /* margin-bottom: 5px; */
  margin: 0;
`;

export const GenderButtonGroup = styled.div`
  display: flex;
  width: 414px;
  justify-content: space-between;
`;

export const GenderButton = styled(Button)`
  width: 159px;
  max-width: 159px;
  text-transform: capitalize;
  border: ${(props) => !props.selected && "1px solid #EDF1F7"};
  color: ${(props) => !props.selected && "#8F9BB3"};
  background-color: transparent;
`;

export const Select = styled.select`
  width: 414px;
  padding: 7px;
  font-size: 16px;
  border: 1px solid #e4e9f2;
  border-radius: 5px;
  height: 40px;
  background-color: #fafafa;
`;

export const DivInput = styled.div`
  text-align: ${(props) => (props.mobile ? "left" : "end")};
  @media (max-width: 991px) {
    text-align: left;
  }
`;

export const ContentForm = styled.div`
  @media (min-width: 680px) and (max-width: 992px) {
    height: ${(props) =>
      props.type === "edit" ? "100%" : "calc(80vh - 20px)"};
    overflow-x: hidden;
  }
  @media (max-width: 679px) {
    height: ${(props) =>
      props.type === "edit" ? "80vh" : "calc(80vh - 20px)"};
    overflow-x: hidden;
  }
`;

export const TitleMain = styled.h3`
  color: #000;
`;
