import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { cardFieldsConsultationPatient } from "../../../user/pages/UserAccount/ConsultationItem/ConsultationCard/cardFielsa/cardFieldsConsultationPatient";
import { getRequest, postRequest } from "../../../../base/Requests";
import { SET_SHOW_LOADER, SHOW_DIALOG } from "../../../../AppReduser";
import CardInfo from "./CardInfo";
import { AppContext } from "../../../../App";
import { Arrow, TextArea } from "./style";
import Button from "react-bootstrap/Button";
import { navigate } from "hookrouter";
import CodeDispatch from "../../../../module/CodeDispatch/codeDispatch";
import { Card } from "../../../../module/InformationCard/style";
import {
  DescriptionCard,
  DescriptionError,
} from "../../../insurance-admin/pages/Patient/stylePatient";
import PropTypes from "prop-types";

const CardReason = ({ id }) => {
  const [data, setData] = useState([]);
  const [valueText, setValueText] = useState("");
  const [error, setError] = useState(false);

  const textArea = useRef(null);

  const { dispatch, state } = useContext(AppContext);

  useEffect(() => {
    dispatch({
      type: SET_SHOW_LOADER,
      payload: true,
    });

    getRequest(`book/doctor/${id}/`)
      .then((res) => {
        setData(res.data);

        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
      })
      .catch((err) => {
        console.log("err", err);

        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
      });
  }, []);

  const handleDeleteBook = () => {
    if (!valueText.length) {
      setError(true);
      textArea.current.focus();
      return false;
    }

    dispatch({
      type: SET_SHOW_LOADER,
      payload: true,
    });

    const body = {
      clinic: data?.clinic?.id,
      syn_id: data?.syn_id,
      meta: { text: valueText },
    };

    postRequest(`integration/clinic/talon/unlock`, body)
      .then((res) => {
        dispatch({
          type: SHOW_DIALOG,
          payload: {
            type: "success",
            status: true,
            message: (
              <>
                <h3>Консультация отменена!</h3>
                <p>Уведомление будет выслано на контактный E-mail пациента</p>
              </>
            ),
          },
        });
        navigate("/");

        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
      })
      .catch((err) => {
        console.log("err", err);

        const error = err.data.errors;
        codeDispatch(
          "error",
          "Ошибка",
          error ? error.text : "Произошла ошибка"
        );

        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
      });
  };

  const codeDispatch = (type, header, title) => {
    dispatch({
      type: SHOW_DIALOG,
      payload: {
        status: true,
        type: type,
        message: (
          <>
            <h1>{header}</h1>
            <br />
            <p>{title}</p>
          </>
        ),
      },
    });
  };

  return (
    <Container fluid className="mt-4">
      <Card>
        <Row>
          <Col>
            <Arrow onClick={() => navigate("/")} />
          </Col>
        </Row>
        <Row lg={12} className="mt-3">
          <CardInfo fields={cardFieldsConsultationPatient(data)} />
        </Row>
        <Row lg={12}>
          <Col lg={6}>
            <DescriptionCard>
              *Укажите причину отмены консультации
            </DescriptionCard>
            <TextArea
              ref={textArea}
              error={!valueText && error}
              placeholder="Введите текст..."
              onChange={(e) => setValueText(e.target.value)}
              className="mt-3"
            />
            {error && !valueText.length && (
              <DescriptionError>*Обязательное поле</DescriptionError>
            )}
          </Col>
        </Row>
        <Row lg={12} className="d-block">
          <Col className="mt-4">
            <DescriptionCard className="mt-4">
              *Уведомление будет выслано на контактный E-mail пациента
            </DescriptionCard>
          </Col>
          <Col lg={3}>
            <Button
              variant="danger"
              className="mt-3 mb-4 w-100"
              onClick={handleDeleteBook}
            >
              Отменить консультацию
            </Button>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

CardReason.propTypes = {
  id: PropTypes.string,
};

export default CardReason;
