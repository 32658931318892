export const SHOW_DIALOG = 0;
export const SHOW_LOADER = 1;
export const AUTHORIZE = 2;
export const PAYMENT = 3;
export const SET_ACCESS_TOKEN = 4;
export const SET_JITSI_CONFIG = 5;
export const SET_DOCTOR = 6;
export const IN_CALL = 7;
export const SET_CALL_DATA = 8;
export const SET_FIRST_LAUNCH = 9;
export const SHOW_ADMIN_DIALOG = 10;
export const SET_CALL_TIME = 11;
export const SET_SHOW_LOADER = 12;
export const SHOW_SEARCH = 13;
export const SEARCH_DATA = 14;
export const MENU = 15;
export const USER_CHAT = 16;
export const USER_DATA = 17;
export const SHOW_DIALOG_ADMIN = 18;
export const ID_RECORDING_DOCTOR_BOOK = 19;
export const USER_PHOTO = 20;
export const SOCKET_DATA = 21;

export const initialState = {
  showDialog: {
    status: false,
    message: "",
    confirm: () => {},
  },
  showDialogAdmin: {
    status: false,
    message: "",
    confirm: () => {},
  },
  showAdminDialog: {
    status: false,
    message: "",
    confirm: () => {},
  },
  showLoader: false,
  role: localStorage.getItem("role"),
  accessToken: localStorage.getItem("access_token"),
  payment: {
    status: false,
    fee: 0,
    type: "",
    date: "",
    time: "",
    bookId: null,
    confirm: () => {},
  },
  jitsiConfig: [],
  doctor: null,
  username: localStorage.getItem("username"),
  isFirst: localStorage.getItem("first_launch"),
  callTime: 0,
  inCall: false,
  loading: false,
  showSearch: null,
  searchData: "",
  menu: false,
  userChat: null,
  userData: null,
  idRecordingDoctorBook: null,
  userPhoto: false,
  socketData: [],
};

export const appReducer = (state, action) => {
  // eslint-disable-next-line
  switch (action.type) {
    case SHOW_DIALOG:
      return { ...state, showDialog: action.payload };
    case SOCKET_DATA:
      return { ...state, socketData: action.payload };
    case ID_RECORDING_DOCTOR_BOOK:
      return { ...state, idRecordingDoctorBook: action.payload };
    case SHOW_DIALOG_ADMIN:
      return { ...state, showDialogAdmin: action.payload };
    case SHOW_LOADER:
      return { ...state, showLoader: action.payload };
    case MENU:
      return { ...state, menu: action.payload };
    case USER_DATA:
      return { ...state, userData: action.payload };
    case USER_CHAT:
      return { ...state, userChat: action.payload };
    case AUTHORIZE:
      return { ...state, role: action.payload };
    case PAYMENT:
      return { ...state, payment: action.payload };
    case SET_ACCESS_TOKEN:
      return { ...state, accessToken: action.payload };
    case SET_JITSI_CONFIG:
      return { ...state, jitsiConfig: action.payload };
    case SET_DOCTOR:
      return { ...state, doctor: action.payload };
    case IN_CALL:
      return { ...state, inCall: action.payload };
    case SET_CALL_DATA:
      return { ...state, roomName: action.payload };
    case SET_FIRST_LAUNCH:
      return { ...state, isFirst: action.payload };
    case SHOW_ADMIN_DIALOG:
      return { ...state, showAdminDialog: action.payload, showSearch: null };
    case SET_CALL_TIME:
      return { ...state, callTime: action.payload };
    case SET_SHOW_LOADER:
      return { ...state, loading: action.payload };
    case SHOW_SEARCH:
      return { ...state, showSearch: action.payload };
    case SEARCH_DATA:
      return { ...state, searchData: action.payload };
    case USER_PHOTO:
      return { ...state, userPhoto: action.payload };
  }
};
