import moment from "moment";

export const cardFieldsConsultationPatient = (data = []) => [
  {
    id: 1,
    title: "Дата:",
    data: data?.datetime_from
      ? moment(data?.datetime_from).format("DD.MM.YYYY")
      : "-",
  },
  {
    id: 2,
    title: "Время:",
    data: data?.datetime_from
      ? moment(data?.datetime_from).format("HH:mm")
      : "-",
  },
  {
    id: 3,
    title: "Специализация:",
    data: data?.doctor?.specialty?.join(", ") || "-",
  },
  {
    id: 4,
    title: "ФИО:",
    data: `${data?.doctor?.last_name || "-"} ${data?.doctor?.first_name || "-"} ${
      data?.doctor?.middle_name || "-"
    }`,
  },
];
