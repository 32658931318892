import styled from "styled-components";
import { Button, CardDescription } from "../../../../../common/Components";
import { ReactComponent as Video } from "../../../../../assets/camera.svg";
import { ReactComponent as Clock } from "../../../../../assets/clock.svg";
import { ReactComponent as Card } from "../../../../../assets/card.svg";
import { ReactComponent as Locations } from "../../../../../assets/locations.svg";
import { HeaderButtons } from "../../doctors/Components";

export const Avatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

export const AppointmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 0 0 20px;
`;

export const DoctorInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const DoctorData = styled.div`
  line-height: 0;
  margin-left: 15px;
`;

export const DoctorName = styled.p`
  margin-top: 25px;
  font-weight: bold;
`;

export const NewCardDescription = styled(CardDescription)`
  font-size: 12px;
  margin: 0;
`;

export const Details = styled.div`
  margin: 15px 0 15px;
`;

export const Center = styled.div`
  text-align: center;
`;

export const Info = styled.div`
  margin: 15px 0 0;
  padding: 15px 0 0;
  border-top: 1px solid #edf1f7;
`;

export const WrapperButton = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15px;
  align-items: center;
  margin: 15px 0 0;
  padding: 0 0 15px;
  border-bottom: 1px solid #edf1f7;
  @media (max-width: 414px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Title = styled.p`
  margin: 0 0 7px;
  font-size: 13px;
  color: #222b45;
  font-weight: 700;
`;

export const NewButton = styled(Button)`
  width: 100%;
  justify-content: center;
`;

export const ButtonBack = styled(Button)`
  padding: 0 20px;
  justify-content: center;
`;

export const Ul = styled.ul`
  margin: 0;
  padding: 0;
  font-size: 13px;

  li {
    display: grid;
    grid-template-columns: 80% 20%;
    align-items: center;
    list-style: none;
    padding: 15px 20px;
    border-bottom: 1px solid #edf1f7;

    &:last-child {
      border: none;
    }

    span:last-child {
      text-align: right;
    }
  }
`;

export const InfoUl = styled(Ul)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15px;
  align-items: center;
  padding: 0 15px;
  @media (max-width: 385px) {
    grid-template-columns: 1fr;
  }

  li {
    display: flex;
    margin: 0 0 12px;
    padding: 0;
    border: none;
    @media (max-width: 385px) {
      margin: 0 0 20px;
      &:last-child {
        margin: 0;
      }
    }

    & svg {
      width: 22px;
      vertical-align: sub;

      & path {
        fill: #8f9bb3;
      }
    }
  }
`;

export const VideoIcon = styled(Video)`
  & path {
    fill: #c5cee0;
  }
`;

export const ClockIcon = styled(Clock)`
  & path {
    fill: #c5cee0;
  }
`;

export const CardIcon = styled(Card)`
  & path {
    fill: #c5cee0;
  }
`;

export const LocationsIcon = styled(Locations)`
  width: 16px;
  height: 20px;

  & path {
    fill: #c5cee0;
  }
`;

export const TextRight = styled.div`
  text-align: right;
  @media (max-width: 475px) {
    margin-top: 10px;
  }

  svg {
    vertical-align: middle;
    margin: 0 10px;
    @media (max-width: 475px) {
      margin: 0;
      &:first-child {
        margin-right: 10px;
      }

      &:last-child {
        margin-left: 10px;
      }
    }
  }
`;

export const TextCenter = styled.div`
  text-align: center;
`;

export const Link = styled.a`
  font-size: 12px;
  color: #6574cf;
  text-decoration: none;
`;

export const NewHeaderButtons = styled(HeaderButtons)`
  width: calc(100% - 30px);
  align-items: center;
  margin: 15px 0 0 15px;
`;

export const Description = styled(CardDescription)`
  padding: 0 15px !important;
`;

export const NewAvatar = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-image: url(${(props) => props.url});
  background-position: center;
  background-size: cover;
`;

export const Span = styled.div`
  margin-left: 5px;
`;

export const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid #edf1f7;
  margin-bottom: 10px;
  background-color: white;
`;

export const Tab = styled.div`
  width: 50%;
  padding: 10px 0;
  border-bottom: ${(props) => (props.selected ? "2px solid #6574CF" : "none")};
  color: ${(props) => (props.selected ? "#6574CF" : "#8F9BB3")};
  display: flex;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    transition: ease 0.3s;
    color: #6574cf;
  }
`;

export const AppointmentPaper = styled.div`
  display: block;
  background-color: white;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 1s ease 0s;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const AppointmentTop = styled.div`
  border-bottom: 1px solid #edf1f7;
`;

export const AppointmentBottom = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;

  & button {
    margin-left: 10px;
  }
`;

export const Date = styled.div`
  /*line-height: 5px;*/
`;

export const Duration = styled.p`
  display: flex;
  align-items: center;
  color: #8f9bb3;
  justify-content: flex-end;
  padding: 0 20px;
`;

export const TextNotify = styled.p`
  margin-top: 100px;
  text-align: center;
  font-size: 30px;
  color: #8f9bb3;
`;
