import React, { useContext, useEffect, useRef, useState } from "react";
import "moment/locale/ru";
import { AppContext } from "../../../../../App";
import { SliderBlock, Text } from "./style";
import { Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { config } from "../../../../../config";
import { Events } from "react-scroll";
import CardFielsaPatient from "../../Appointment/CardFielsa/CardFielsaPatient";
import CardFielsaDoctor from "../../Appointment/CardFielsa/CardFielsaDoctor";

const ConsultationItem = ({ data, action }) => {
  const { dispatch, state } = useContext(AppContext);
  const userIdAccess = localStorage.getItem("access_token");
  const ws = useRef(null);

  const [res, setRes] = useState(null);
  const [convertData, setConvertData] = useState([]);

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          right: "-11px",
        }}
        onClick={onClick}
      />
    );
  };

  useEffect(() => {
    if (data && action) {
      ws.current = new WebSocket(
        `${config.wsType}://${config.ws}/asgi/counter/?access_token=${userIdAccess}`
      );
      ws.current.onopen = () => {
        console.log("ws opened");
        ws.current.send(JSON.stringify({ cmd: "get_message" }));
      };
      ws.current.onclose = () => console.log("ws closed");

      ws.current.onmessage = (e) => {
        setRes(JSON.parse(e.data));
      };

      Events.scrollEvent.register("begin");
      Events.scrollEvent.register("end");

      return () => {
        ws.current.close();
        Events.scrollEvent.remove("begin");
        Events.scrollEvent.remove("end");
      };
    }
  }, []);

  const filterCounter = (objCounter) => {
    if (!objCounter) return false;
    let list = [];
    data?.map((item) => {
      Object.entries(objCounter).map(([key, count]) => {
        +key === +item.id && list.push({ ...item, count });
      });
    });
    setConvertData(list);
  };

  const filterCounterUpdated = (objCounter) => {
    if (!objCounter) return false;
    let list = [];
    convertData?.map((item) => {
      Object.entries(objCounter).map(([key, count]) => {
        +key === +item.id
          ? list.push({ ...item, count })
          : list.push({ ...item });
      });
    });

    setConvertData(list);
  };

  useEffect(() => {
    if (res && convertData && convertData.length) {
      filterCounterUpdated(res);
    } else filterCounter(res);
  }, [res]);

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          left: "-10px",
        }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1630,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const nameData = action && res ? convertData : data;

  const dataMap = () => {
    return nameData?.map((e) => {
      return (
        <div className="p-15" key={e.id}>
          {state.role === "patient" ? (
            <CardFielsaPatient key={e.id} e={e} action={action} />
          ) : (
            <CardFielsaDoctor
              key={e.id}
              e={e}
              action={action}
              role={state.role}
            />
          )}
        </div>
      );
    });
  };

  return (
    <>
      {nameData && nameData.length ? (
        <Col lg={12} className="mt-3">
          <SliderBlock>
            <Slider {...settings}>{dataMap()}</Slider>
          </SliderBlock>
        </Col>
      ) : (
        <Text>Нет записей</Text>
      )}
    </>
  );
};

export default ConsultationItem;
