import styled from "styled-components";

export const Item = styled.div`
  padding: 15px;
  color: #222b45;
  font-weight: 600;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: 0 0 10px #ccc;
  ${(props) => !props.clickOn && "cursor: pointer"};

  h5 {
    word-wrap: break-word;
  }

  &:hover {
    box-shadow: 0 0 10px #000;
  }

  p {
    margin: 0;
  }

  svg {
    width: 65px;
    height: auto;
  }
`;
