import { Item } from "../../../../insurance-admin/pages/InsuranceProgram/InsuranceProgramItem/style";
import { Col, Row } from "react-bootstrap";
import {
  InfoBooking,
  NewCounter,
} from "../../UserAccount/ConsultationItem/style";
import {
  DescriptionCard,
  Profile,
  TitleCard,
} from "../../../../insurance-admin/pages/Patient/stylePatient";
import moment from "moment";
import React from "react";
import { Button } from "../../../../../common/Components";
import { navigate } from "hookrouter";

const CardFielsaPatient = ({ e, action }) => {
  const handleHref = ({ id }) => {
    if (action) {
      navigate(`/consultation/${id}`);
    } else navigate(`/consultation/past/${id}`);
  };

  return (
    <Item onClick={() => handleHref(e)}>
      <Row>
        <Col lg={12} className="d-flex justify-content-start align-self-center">
          <InfoBooking>
            {action && e.count > 0 && <NewCounter>{e.count}</NewCounter>}
            <Profile>
              <DescriptionCard>Дата:</DescriptionCard>
              <TitleCard>
                {e?.datetime_from
                  ? moment(e?.datetime_from).format("DD.MM.YYYY")
                  : "-"}
              </TitleCard>
            </Profile>
            <Profile>
              <DescriptionCard>Время:</DescriptionCard>
              <TitleCard>
                {e?.datetime_from
                  ? moment(e?.datetime_from).format("HH:mm")
                  : "-"}
              </TitleCard>
            </Profile>
            <Profile>
              <DescriptionCard>Клиника:</DescriptionCard>
              <TitleCard>{e?.clinic?.name || "-"}</TitleCard>
            </Profile>
            <Profile>
              <DescriptionCard>Врач:</DescriptionCard>
              <TitleCard>
                {e?.doctor ? e?.doctor?.specialty?.join(", ") : "-"}
              </TitleCard>
            </Profile>
            <Profile>
              <DescriptionCard>ФИО:</DescriptionCard>
              <TitleCard>
                {e?.doctor?.last_name || "-"} {e?.doctor?.first_name || "-"}{" "}
                {e?.doctor?.middle_name || "-"}
              </TitleCard>
            </Profile>
          </InfoBooking>
        </Col>
      </Row>
      <Row className="mt-4">
        <React.Fragment>
          <Col sm={12} className="mb-3 mb-lg-0">
            <Button variant="cancel" className="w-100 fz10">
              Подробнее
            </Button>
          </Col>
        </React.Fragment>
      </Row>
    </Item>
  );
};

export default CardFielsaPatient;
