import { MenuItem } from "../HeaderStyle";
import { navigate } from "hookrouter";
import React from "react";

export const menuList = (data, setMenuMob) => {
  const path = window.location.pathname;
  return data.map((e) => {
    return (
      <MenuItem
        key={e.id}
        onClick={() => {
          navigate(`${e.href}`, false);
          setMenuMob(false);
        }}
        active={path === e.href}
      >
        <a>{e.img}</a> {e.title}
      </MenuItem>
    );
  });
};
