import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getRequest, postRequest } from "../../../base/Requests";
import { Card } from "../../user/pages/UserAccount/style";
import { SET_SHOW_LOADER, SHOW_DIALOG } from "../../../AppReduser";
import { AppContext } from "../../../App";
import { DescriptionCard } from "../../insurance-admin/pages/Patient/stylePatient";
import Button from "react-bootstrap/Button";
import {
  CardLabel,
  ProgramList,
} from "../../insurance-admin/pages/InsuranceInfo/style";
import { dataTime } from "./dataTime";
import { Text } from "../../user/pages/UserAccount/ConsultationItem/style";

const SettingClinic = ({ mobile }) => {
  const { dispatch, state } = useContext(AppContext);

  const [checkedTime, setCheckedTime] = useState(null);
  const [shouldUpdate, setShouldUpdate] = useState(true);

  useEffect(() => {
    dispatch({
      type: SET_SHOW_LOADER,
      payload: true,
    });
    getRequest("clinic/settings/get_data_from_clinic_settings/")
      .then((res) => {
        const time = res.data.consultation_cancellation_time;
        setCheckedTime(time);
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
      });
  }, [shouldUpdate]);

  const handleSave = () => {
    const body = {
      CancelTime: checkedTime,
    };
    postRequest("clinic/settings/update_clinic_settings/", body)
      .then((res) => {
        dispatch({
          type: SHOW_DIALOG,
          payload: {
            type: "success",
            status: true,
            message: !mobile ? (
              <h2>Изменения сохранены!</h2>
            ) : (
              <h5>Изменения сохранены!</h5>
            ),
          },
        });
        setShouldUpdate(!shouldUpdate);
      })
      .catch((err) => {
        console.log("err", err);
        codeDispatch("error", "Ошибка", "Ошибка");
      });
  };

  const listTime = () => {
    return dataTime.map(({ id, time, title }) => {
      return (
        <CardLabel key={`${id}_${time}`} className="mt-2">
          <ProgramList className="p-1 mb-2" checked={time === +checkedTime}>
            <Row>
              <Col className="align-self-center mb-lg-0">
                <input
                  value={time}
                  id={time}
                  checked={time === +checkedTime}
                  className="ml-2 mr-2"
                  type="radio"
                  name="time"
                  onChange={(e) => setCheckedTime(e.target.id)}
                />
                <span>{`${time} ${title}`}</span>
              </Col>
            </Row>
          </ProgramList>
        </CardLabel>
      );
    });
  };

  const codeDispatch = (type, header, title) => {
    dispatch({
      type: SHOW_DIALOG,
      payload: {
        status: true,
        type: type,
        message: (
          <>
            <h1>{header}</h1>
            <br />
            <p>{title}</p>
          </>
        ),
      },
    });
  };

  return (
    <React.Fragment>
      {checkedTime ? (
        <Card className="mt-3 mb-4">
          <Row className="ml-2">
            <DescriptionCard>
              Настройка времени до отмены консультации:
            </DescriptionCard>
          </Row>
          <Row lg={4} className="d-flex flex-column mt-2 ml-2">
            {listTime()}
          </Row>
          <Row className="ml-2 mt-2">
            <Button variant="primary" onClick={() => handleSave()}>
              Сохранить
            </Button>
          </Row>
        </Card>
      ) : (
        <Text>Список настроек пуст</Text>
      )}
    </React.Fragment>
  );
};
export default SettingClinic;
