import styled from "styled-components";
import {Button} from "../../../../common/Components";
import {Col, Row} from "react-bootstrap";

export const DoctorLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardHeader = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  background-color: white;

  h2 {
    margin-top: 15px;
    font-size: 1.5em;
  }

  & img {
    height: 124px;
    width: 124px;
    border-radius: 50%;
  }
`;

export const Rating = styled.div`
  color: #6574cf;
  font-weight: bolder;
  display: flex;
  align-items: center;
`;

export const TimeCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const TimeCard = styled.div`
  background-color: white;
  margin: 5px;
  height: 120px;
  padding: 10px;
`;

export const TimeCardbutton = styled(Button)`
  color: #6574cf;
  border: 2px #6574cf solid;
  border-radius: 8px;
  height: 30px;
  font-size: 12px;
`;

export const VideoButton = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #6574cf;
`;

export const HeaderButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const IconButton = styled.button`
  width: 40px;
  max-width: 40px;
  height: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(props) =>
          props.type === "message" ? "#6574cf  2px solid;" : "#8f9bb3  2px solid;"};
  border-radius: 8px;

  & svg {
    object-fit: contain;

    & g {
      height: 20px;

      & path {
        ${(props) => props.disabled && "fill: #8f9bb3;"}
      }
    }
  }

  &:hover {
    transition: ease 0.3s;
    background-color: #c2c7d1;
  }
`;

export const Experience = styled.div`
  margin: 30px 0;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 575px) {
    display: grid;
    grid-template-columns: 1fr;
    justify-self: center;
    & :first-child {
      margin-bottom: 5px;
    }
  }

  & svg {
    box-sizing: content-box !important;
    border: 1px solid #edf1f7;
    width: 20px;
    height: 20px;
    padding: 10px;
    border-radius: 8px;
  }

  border-bottom: 1px solid #edf1f7;
  padding-bottom: 10px;
`;

export const ExperienceCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;

  p {
    margin: 0;
  }
`;

export const Link = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: #6574cf;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

export const RowBlock = styled(Row)`
  @media (max-width: 575px) {
    margin: 0 50%;
  }
`;

export const ColBlock = styled(Col)`
  @media (max-width: 575px) {
    margin-bottom: 20px;
    & button {
      width: 200px;
    }
  }
`;

export const Item = styled.div`
  width: calc(100% - 40px);
  display: flex;
  height: 70px;
  background-color: ${(props) => (props.disabled ? "#edf1f7" : "white")};
  align-items: center;
  color: ${(props) => (props.disabled ? "silver" : "black")};
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid #edf1f7;

  & .next {
    color: #8f9bb3;
    font-size: 30px;
  }
`;
