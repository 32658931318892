import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Select from "react-select";
import { SET_SHOW_LOADER, SHOW_DIALOG } from "../../../AppReduser";
import { AppContext } from "../../../App";
import { getRequest } from "../../../base/Requests";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import ConsultationItem from "../../user/pages/UserAccount/ConsultationItem/ConsultationItem";
import InformationCard from "../../../module/InformationCard/InformationCard";
import { cardFieldsConsultationClinic } from "../../user/pages/UserAccount/ConsultationItem/ConsultationCard/cardFielsa/cardFieldsConsultationClinic";
import { scroller } from "react-scroll";
import * as Scroll from "react-scroll";
import { Text } from "../../user/pages/UserAccount/ConsultationItem/style";

const CardClinic = () => {
  const { dispatch, state } = useContext(AppContext);

  const [data, setData] = useState([]);
  const [date, setDate] = useState(moment().format("yyyy-MM-DD"));
  const [selected, setSelected] = useState(null);
  const [clinicId, setClinicId] = useState(null);
  const [specialtyId, setSpecialtyId] = useState(null);
  const [doctorId, setDoctorId] = useState(null);
  const [specialtyData, setSpecilatyData] = useState([]);
  const [doctorData, setDoctorData] = useState([]);
  const [isLoadingClinic, setIsLoadingClinic] = useState(true);
  const [isLoadingDoctor, setIsLoadingDoctor] = useState(false);
  const [upcoming, setUpcoming] = useState([]);
  let Element = Scroll.Element;

  useEffect(() => {
    dispatch({
      type: SET_SHOW_LOADER,
      payload: true,
    });

    let list = [];
    getRequest(`clinic_employers/`)
      .then((res) => {
        const result = res.data.results;
        setClinicId(result[0].clinic.id);

        result[0]?.clinic.speciality.map((name) => {
          list.push({ value: name, label: name });
        });

        setData(result[0]);
        setIsLoadingClinic(false);

        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
        codeDispatch("error", "Ошибка", "Нет данных");
        console.log("err", err);
      });

    setSpecilatyData(list);
  }, []);

  useEffect(() => {
    setDoctorId(null);
  }, [specialtyId]);

  const codeDispatch = (type, header, title) => {
    dispatch({
      type: SHOW_DIALOG,
      payload: {
        status: true,
        type: type,
        message: (
          <>
            <h1>{header}</h1>
            <br />
            <p>{title}</p>
          </>
        ),
      },
    });
  };

  const handleDoctor = (e) => {
    setSpecialtyId(e);
    setIsLoadingDoctor(true);

    let list = [];
    getRequest(`doctor/?specialty=${e}&clinic=${clinicId}`)
      .then((res) => {
        const result = res.data.results;

        result?.map(
          ({
            last_name,
            id,
            middle_name,
            first_name,
            passport,
            meta,
            clinic,
          }) => {
            return list.push({
              value: id,
              label: `${last_name} ${first_name} ${middle_name}`,
              meta,
              passport,
              clinic,
            });
          }
        );
        setIsLoadingDoctor(false);
      })
      .catch((err) => {
        codeDispatch("error", "Ошибка", "Нет данных");
        console.log("err", err);
      });

    setDoctorData(list);
  };

  const changeDate = (e) => {
    dispatch({
      type: SET_SHOW_LOADER,
      payload: true,
    });

    setSelected(null);

    const dateBook = moment(e).format("DD.MM.YYYY");
    setDate(dateBook);

    getRequest(`book/doctor/?doctor_id=${doctorId}&date=${dateBook}`)
      .then((res) => {
        setUpcoming(res.data.results);
        scrollToBottom();
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
      })
      .catch((err) => {
        console.log("err", err);

        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
      });
  };

  const handleSchedule = (id) => {
    console.log("id", id);

    setDoctorId(id);

    dispatch({
      type: SET_SHOW_LOADER,
      payload: true,
    });

    const nowDate = moment().format("DD.MM.YYYY");
    setDate(nowDate);

    getRequest(`book/doctor/?doctor_id=${id}&date=${nowDate}`)
      .then((res) => {
        setUpcoming(res.data.results);
        scrollToBottom();

        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
      })
      .catch((err) => {
        console.log("err", err);

        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
      });
  };

  const scrollToBottom = () => {
    scroller.scrollTo("bottomElement", {
      containerId: "root",
    });
  };

  return (
    <Container className="mt-4" fluid>
      <InformationCard fields={cardFieldsConsultationClinic(data)} />
      <br />

      <Row className="mt-3 mb-3">
        <Col lg={6} className="mt-4">
          <h5>Выберите специальность врача:</h5>
          <Select
            placeholder="Выберите специальность..."
            isLoading={isLoadingClinic}
            options={specialtyData}
            onChange={(e) => handleDoctor(e.value)}
          />
        </Col>
      </Row>
      {specialtyId && (
        <Row>
          <Col lg={6}>
            <h5>Выберите ФИО врача:</h5>
            <Select
              key={`select_key__${specialtyId}`}
              placeholder="Выберите врача..."
              isLoading={isLoadingDoctor}
              options={doctorData}
              onChange={(e) => {
                handleSchedule(e.value);
              }}
            />
          </Col>
        </Row>
      )}
      {specialtyId && doctorId && (
        <>
          <Row className="mt-4 mb-4">
            <Col lg={4}>
              <h5>Выберите дату консультации</h5>
              <Calendar minDate={new Date()} onChange={(e) => changeDate(e)} />
            </Col>
          </Row>
          {upcoming && (
            <Row>
              <Col>
                <h5>Консультации на {date}:</h5>
                {upcoming && upcoming.length ? (
                  <ConsultationItem data={upcoming} />
                ) : (
                  <Text>Нет записей</Text>
                )}
                <Element id="bottomElement" />
              </Col>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};

export default CardClinic;
