// import { toastr } from 'react-redux-toastr';
import Axios from "axios";
import { navigate } from "hookrouter";
import { config } from "../config";

export function errorHandler(err, url, payload, options = {}) {
  if (err.status === 401) {
    localStorage.removeItem("role");
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    navigate("/", true);
    window.location.reload();
  }
  console.log(
    `${err.status ? err.status : "Error fetching data"}: ${
      err.statusText
    } - ${url}`,
    err,
    payload
  );
  if (!options.preventError) {
    throw err;
  }
  throw err;
}

function handleResponse(res) {
  if (!res.ok) {
    return Promise.reject(res);
  }
  return res;
}

export const getRequest = async (url, header) => {
  let authorize;
  if (localStorage.getItem("access_token")) {
    authorize = {
      Authorization: `JWT ${localStorage.getItem("access_token")}`,
    };
  }
  return Axios.get(config.endpoint + url, {
    headers: authorize,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      let validErr = err.response ? err.response : err;
      errorHandler(validErr, url);
    });
};

export const postRequest = async (url, body) => {
  let authorize;
  if (localStorage.getItem("access_token")) {
    authorize = {
      Authorization: `JWT ${localStorage.getItem("access_token")}`,
    };
  }
  const params = { ...body };

  return Axios.post(config.endpoint + url, params, {
    headers: authorize,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      let validErr = err.response ? err.response : err;
      errorHandler(validErr, url);
    });
};

export const saveFile = async (url) => {
  let authorize;
  if (localStorage.getItem("access_token")) {
    authorize = {
      "Content-Type": "blob",
      Authorization: `JWT ${localStorage.getItem("access_token")}`,
    };

    return Axios({
      method: "GET",
      url: config.endpoint + url,
      responseType: "blob",
      headers: authorize,
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        let validErr = err.response ? err.response : err;
        errorHandler(validErr, url);
      });
  }
};

export const postRequestJWT = async (url, body) => {
  const params = { ...body };

  return Axios.post(config.endpointJWT + url, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      let validErr = err.response ? err.response : err;
      errorHandler(validErr, url);
    });
};

export const postRequestPassport = async (url, body) => {
  let authorize;
  if (!!localStorage.getItem("access_token")) {
    authorize = {
      "Content-Type": "multipart/form-data",
      Authorization: `JWT  ${localStorage.getItem("access_token")}`,
    };
  }

  return Axios.post(config.endpointPassport + url, body, {
    headers: authorize,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      let validErr = err.response ? err.response : err;
      errorHandler(validErr, url);
    });
};

export const postRequestFileClient = async (url, body) => {
  let authorize;
  if (!!localStorage.getItem("access_token")) {
    authorize = {
      "Content-Type": "multipart/form-data",
      Authorization: `JWT ${localStorage.getItem("access_token")}`,
    };
  }

  return Axios.post(config.endpoint + url, body, {
    headers: authorize,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      let validErr = err.response ? err.response : err;
      errorHandler(validErr, url);
    });
};

export const patchRequest = async (url, body) => {
  let authorize;
  if (!!localStorage.getItem("access_token")) {
    authorize = {
      Authorization: `JWT ${localStorage.getItem("access_token")}`,
    };
  }
  const params = { ...body };

  const data = Axios.patch(config.endpoint + url, params, {
    headers: authorize,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      let validErr = err.response ? err.response : err;
      errorHandler(validErr, url);
    });

  return data;
};

export const postFileRequest = async (url, body) => {
  let authorize;
  if (!!localStorage.getItem("access_token")) {
    authorize = {
      Authorization: `JWT ${localStorage.getItem("access_token")}`,
    };
  }
  // const params = { ...body };

  const data = Axios.post(config.endpoint + url, body, {
    headers: {
      ...authorize,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      let validErr = err.response ? err.response : err;
      errorHandler(validErr, url);
    });
  return data;
};

export const putRequest = async (url, body) => {
  let authorize;
  if (!!localStorage.getItem("access_token")) {
    authorize = {
      Authorization: `JWT ${localStorage.getItem("access_token")}`,
    };
  }
  const params = { ...body };

  const data = Axios.put(config.endpoint + url, params, {
    headers: {
      ...authorize,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      let validErr = err.response ? err.response : err;
      errorHandler(validErr, url);
    });
  return data;
};

export const deleteRequest = async (url) => {
  let authorize;
  if (!!localStorage.getItem("access_token")) {
    authorize = {
      Authorization: `JWT ${localStorage.getItem("access_token")}`,
    };
  }
  let params = {
    method: "DELETE",
    headers: {
      ...authorize,
    },
  };
  return await fetch(`${config.endpoint + url}`, params)
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      let validErr = err.response ? err.response : err;
      errorHandler(validErr, url);
    });
};

export const getRequestFetch = async (url, body) => {
  let authorize;
  if (!!localStorage.getItem("access_token")) {
    authorize = {
      Authorization: `JWT ${localStorage.getItem("access_token")}`,
    };
  }
  return await fetch(`${config.endpoint + url}`, {
    method: "GET",
    headers: authorize,
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      let validErr = err.response ? err.response : err;
      if (err.message === "Failed to fetch") {
        throw {};
      } else {
        errorHandler(validErr, url);
      }
    });
};
