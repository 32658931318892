import styled from "styled-components";

export const Text = styled.p`
  margin: 20px 0;
  text-align: center;
  font-size: 30px;
  color: ${({ type }) => (type ? "#f00" : "#8f9bb3")};
`;

export const SliderBlock = styled.div`
  .slick-track {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const InfoBooking = styled.div`
  min-height: 220px;
`;

export const NewCounter = styled.div`
  position: absolute;
  text-align: center;
  top: 0;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ff0000;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
`;
