import styled from "styled-components";
import { Button } from "../../../../../common/Components";

export const BookingLayout = styled.div`
  width: 100%;
  height: 100%;
  max-width: 700px;
  min-height: calc(75vh);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .select {
    max-width: 500px
  }
`;

export const DoctorInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BookingPaper = styled.div`
  margin-top: -20px;
  background-color: white;
  width: 100%;
  min-height: calc(80vh - 50px);
  padding-bottom: 50px;
`;

export const TimePaper = styled.div``;

export const TimeLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

export const TimeItem = styled.div`
  background-color: ${(props) => (props.selected ? "#6574CF" : "#f7f9fc")};
  color: ${(props) =>
    props.selected ? "white" : props.disabled ? "silver" : "black"};
  width: 60px;
  padding: 5px;
  text-align: center;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    color: #fff;
    background-color: #8b96e3;
  }
`;

export const ButtonItems = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

export const NextButton = styled(Button)`
  width: 45%;
`;

export const BookingTabs = styled.div`
  display: flex;
  border-bottom: 1px solid #edf1f7;
  margin-bottom: 10px;
  margin-top: 20px;
`;

export const Tab = styled.div`
  width: 33%;
  padding: 10px 0;
  border-bottom: ${(props) => (props.selected ? "4px solid #6574CF" : "none")};
  color: ${(props) => (props.selected ? "#6574CF" : "#8F9BB3")};
  display: flex;
  justify-content: center;
  font-weight: bold;
  @media (max-width: 355px) {
    width: 30%;
    &:last-child {
      width: 40%;
    }
  }
`;

export const BookingButtonGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  & button {
    width: 49%;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  font-size: 16px;
  background-color: #f7f9fc;
  border: 1px solid #e4e9f2;
  border-radius: 5px;
  resize: none;
`;

export const Select = styled.select`
  -webkit-appearance: none;
  width: 100%;
  font-size: 16px;
  background-color: #f7f9fc;
  border: 1px solid #e4e9f2;
  border-radius: 5px;
  height: 40px;
  padding: 0 15px;
`;

export const Fee = styled.p`
  color: #6574cf;
  font-weight: bolder;

  & span {
    font-size: 18px;
  }
`;

export const DetailsItem = styled.div`
  border-bottom: 1px solid #e4e9f2;
  padding: 15px;
`;
export const SelectButton = styled(Button)`
  border: ${(props) =>
    props.selected ? "1px solid #6574CF" : "1px solid #e4e9f2"};
  color: ${(props) => (props.selected ? "#6574CF" : "#8F9BB3")};

  &.active {
    background-color: #fff;
  }
`;

export const DoctorCred = styled.div`
  display: flex;
`;

export const Avatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

export const Detail = styled.div`
  width: 100%;
  min-height: 72px;
  border-bottom: 1px solid #e4e9f2;
  display: flex;
  align-items: center;

  & p {
    margin: 0;
    font-size: 13px;
  }
`;

export const PaymentDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #6574cf;
  padding: 0 10px;
  border-radius: 4px;
  background-color: #f7f9fc;
  color: #6574cf;
  font-weight: bold;

  & span {
    font-size: 13px;
    font-weight: normal;
  }
`;

export const UploadButton = styled(Button)`
  padding-left: 15px;
  margin-top: 10px;
`;

export const FileDownload = styled.label`
  display: block;
  margin-top: 20px;

  &::before {
    content: "Выбрать файл";
    display: inline-block;
    color: #fff;
    border: 1px solid #6574cf;
    background-color: #6574cf;
    border-radius: 4px;
    padding: 8px 20px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 10pt;
    margin-right: 10px;
  }

  &:hover::before,
  &:active::before {
    color: #6574cf;
    background-color: #fff;
  }

  & input {
    display: none;
  }
`;

export const Span = styled.span`
  display: inline-block;
  width: 300px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: sub;
`;
