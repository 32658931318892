import styled from "styled-components";
import { ReactComponent as BackArrow } from "../../../../../assets/back-arrow.svg";

export const CardInsurance = styled.div`
  padding: 10px 20px;
  background-color: #fff;

  p:last-child {
    margin: 0;
  }
`;

export const NewBackArrow = styled(BackArrow)`
  & path {
    fill: #6574cf;
  }
`;
