export const errorProcessing = (err) => {
  return Object.keys(err?.data).map((itm) => {
    const a = err?.data[itm];
    if (a?.length) {
      return a;
    } else {
      return Object.keys(a).map((e) => [a[e]]);
    }
  });
};
