import React from 'react'
import {Col} from "react-bootstrap";
import {ProfileBlock} from "../../../../module/InformationCard/style";
import {DescriptionCard, Profile, TitleCard} from "../../../insurance-admin/pages/Patient/stylePatient";
import PropTypes from "prop-types";

const CardInfo = ({fields}) => {

  const cardFields = () =>
    fields?.map((e) => (
      <Profile key={e.id}>
        <DescriptionCard>{e.title}</DescriptionCard>
        <TitleCard>{e.data}</TitleCard>
      </Profile>
    ));

  return (
    <Col
      lg={6}
      className="d-flex align-items-center text-left mt-md-2 mb-md-2"
    >
      <ProfileBlock>
        {cardFields()}
      </ProfileBlock>
    </Col>
  )
}

CardInfo.propTypes = {
  fields: PropTypes.array,
};

export default CardInfo