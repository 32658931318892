import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { Card, Photo, PhotoBlock, ProfileBlock, TimeBlock } from "./style";
import {
  DescriptionCard,
  Profile,
  TitleCard,
} from "../../pages/insurance-admin/pages/Patient/stylePatient";
import { config } from "../../config";
import DateTime from "../DateTime/DateTime";
import InsuranceDetails from "../../pages/user/components/insuranсe/insuranceDetails/InsuranceDetails";
import PropTypes from "prop-types";
import { getRequest, postRequest } from "../../base/Requests";
import {
  IN_CALL,
  SET_CALL_DATA,
  SET_CALL_TIME,
  SET_SHOW_LOADER,
  SHOW_DIALOG,
} from "../../AppReduser";
import { AppContext } from "../../App";
import { navigate } from "hookrouter";
import Button from "react-bootstrap/Button";
import moment from "moment";

const InformationCard = ({ fields, noImage, data, action }) => {
  const { dispatch, state } = useContext(AppContext);

  const codeDispatch = (type, header, title) => {
    dispatch({
      type: SHOW_DIALOG,
      payload: {
        status: true,
        type: type,
        message: (
          <>
            <h1>{header}</h1>
            <br />
            <p>{title}</p>
          </>
        ),
      },
    });
  };

  const urlImage =
    JSON.parse(localStorage.getItem("userHeader")).photo || config.defaultIcon;

  const cardFields = () =>
    fields?.map((e) => (
      <Profile key={e.id}>
        <DescriptionCard>{e.title}</DescriptionCard>
        <TitleCard>{e.data}</TitleCard>
      </Profile>
    ));

  const handleDeleteBook = () => {
    dispatch({
      type: SET_SHOW_LOADER,
      payload: true,
    });

    const body = {
      clinic: data?.clinic?.id,
      syn_id: data?.syn_id,
    };
    postRequest(`integration/clinic/talon/unlock`, body)
      .then((res) => {
        dispatch({
          type: SHOW_DIALOG,
          payload: {
            type: "success",
            status: true,
            message: <h2>Консультация отменена!</h2>,
          },
        });
        navigate(`/`);

        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
      })
      .catch((err) => {
        console.log("err", err);

        const error = err.data.errors;
        codeDispatch(
          "error",
          "Ошибка",
          error
            ? error
            : error.hasOwnProperty("text")
            ? error.text
            : "Произошла ошибка"
        );

        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
      });
  };

  const handleJoinRoom = (id, slug) => {
    navigate(`/consultation-video/&${slug}&${id}`);
  };

  const newDate = (action) => {
    if (action === "cancel") {
      if (state.role === "patient") {
        return new Date() >= new Date(data?.datetime_to);
      } else return new Date() >= new Date(data?.datetime_from);
    } else return new Date() <= new Date(data?.datetime_from);
  };

  return (
    <Card>
      <Row>
        {!noImage && (
          <Col
            lg={2}
            className="d-flex align-items-center justify-content-center"
          >
            <PhotoBlock>
              <Photo src={urlImage} alt="avatar" />
            </PhotoBlock>
          </Col>
        )}
        <Col
          lg={noImage ? "6" : "4"}
          className="d-flex align-items-center text-left mt-md-2 mb-md-2"
        >
          <ProfileBlock>{cardFields()}</ProfileBlock>
        </Col>
        <Col lg={3}>
          {noImage && action === "upcoming" && (
            <>
              {state.role !== "clinic_admin" && (
                <Button
                  variant="primary"
                  className="w-100 mb-2"
                  onClick={() => handleJoinRoom(data?.id, data?.slug)}
                >
                  Начать телемедицинскую консультацию
                </Button>
              )}
              {state.role !== "doctor" && (
                <Button
                  variant="danger"
                  className="mt-3 mb-4 w-100"
                  disabled={newDate("cancel")}
                  onClick={() =>
                    state.role === "clinic_admin"
                      ? navigate(`/consultation/reason/${data.id}`)
                      : handleDeleteBook()
                  }
                >
                  Отменить консультацию
                </Button>
              )}
            </>
          )}
        </Col>
        <Col
          lg={3}
          className="d-flex align-items-start justify-content-end text-right"
        >
          <TimeBlock>
            <span>Сегодня</span>
            <div>
              <DateTime format={"HH:mm / DD.MM.YYYY"} />
            </div>
          </TimeBlock>
        </Col>
      </Row>
    </Card>
  );
};

InsuranceDetails.propTypes = {
  fields: PropTypes.array,
  data: PropTypes.array,
  action: PropTypes.string,
};

export default InformationCard;
