import React, { useContext, useEffect, useState } from "react";
import { СustomFileUpload } from "../../../user/components/chat/messanger/MessangerStyle";
import { Span } from "../../../admin/pages/doctors/DoctorsStyle";
import { Clear } from "../../../insurance-admin/pages/InsuranceInfo/style";
import { NewButton } from "../../../insurance-admin/pages/Insurance/Insurances";
import FormData from "form-data";
import { SET_SHOW_LOADER, SHOW_DIALOG } from "../../../../AppReduser";
import {
  deleteRequest,
  getRequest,
  postRequest,
  postRequestFileClient,
  saveFile,
} from "../../../../base/Requests";
import { AppContext } from "../../../../App";
import * as FileSaver from "file-saver";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { Button, TableCell } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TextField,
} from "material-ui";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "rc-pagination";
import localeInfo from "../../../../module/Pagination/ru_Ru";
import { navigate } from "hookrouter";
import { errorProcessing } from "../../../../module/functions/errorProcessing";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
  },
}));

const AddSchedule = ({ mobile }) => {
  const { dispatch } = useContext(AppContext);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState("");
  const [isLoadingClinic, setIsLoadingClinic] = useState(true);
  const [doctorData, setDoctorData] = useState(null);
  const [specialtyData, setSpecialtyData] = useState([]);
  const [specialtyId, setSpecialtyId] = useState(null);
  const [isLoadingDoctor, setIsLoadingDoctor] = useState(false);
  const [clinicId, setClinicId] = useState(null);
  const [data, setData] = useState([]);
  const [dataTable, setDataTable] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [dataTime, setDataTime] = React.useState("");
  const [itemId, setItemId] = React.useState("");
  const [couponId, setCouponId] = React.useState("");
  const [doctor, setDoctor] = React.useState(null);
  const [total, setTotal] = useState(null);
  const [nextUrl, setNextUrl] = useState(null);
  const [testCurrent, setTestCurrent] = useState(1);
  const [reload, setReload] = useState(false);

  const codeDispatch = (type, header, title) => {
    dispatch({
      type: SHOW_DIALOG,
      payload: {
        status: true,
        type: type,
        message: (
          <>
            <h1>{header}</h1>
            <br />
            <p>{title}</p>
          </>
        ),
      },
    });
  };

  useEffect(() => {
    const list = [];

    getRequest(`clinic_employers/`)
      .then((res) => {
        const result = res.data.results;
        setClinicId(result[0].clinic.id);

        result[0]?.clinic.speciality.map((name) => {
          list.push({ value: name, label: name });
        });
        setData(result[0]);
        setIsLoadingClinic(false);

        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
        codeDispatch("error", "Ошибка", "Нет данных");
        console.log("err", err);
      });

    setSpecialtyData(list);
  }, []);

  const handleAttachment = ({ files, value }) => {
    const name = files[0].name;
    const file = new FormData();
    file.append("file", files[0]);

    setFileName(name);
    setFile(file);
  };

  const handleClose = () => {
    setFile(null);
    setFileName("");
  };

  const handleAddFile = () => {
    dispatch({
      type: SET_SHOW_LOADER,
      payload: true,
    });
    postRequestFileClient(
      "clinic/doctor_schedule/upload_doctor_schedule_excel/",
      file
    )
      .then((res) => {
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
        dispatch({
          type: SHOW_DIALOG,
          payload: {
            type: "success",
            status: true,
            message: res.data.result,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: SHOW_DIALOG,
          payload: {
            status: true,
            type: "error",
            message: (
              <>
                <h1>Ошибка!</h1>
                <p>{err?.data?.errors}</p>
              </>
            ),
          },
        });
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
      });
  };

  const downloadTemplate = () => {
    getRequest("clinic/doctor_schedule/download_sample_doctor_schedule/").then(
      (res) => {
        FileSaver.saveAs(res.data.result, "samplefile.xlsx");
      }
    );
  };

  const handleDoctor = (e) => {
    setDataTable(null);
    setDoctorData(null);
    setSpecialtyId(e);
    setIsLoadingDoctor(true);

    let list = [];
    getRequest(`doctor/?specialty=${e}&clinic=${clinicId}`)
      .then((res) => {
        const result = res.data.results;

        result?.map(
          ({
            last_name,
            id,
            middle_name,
            first_name,
            passport,
            meta,
            clinic,
          }) => {
            return list.push({
              value: id,
              label: `${last_name} ${first_name} ${middle_name}`,
              meta,
              passport,
              clinic,
            });
          }
        );
        setIsLoadingDoctor(false);
      })
      .catch((err) => {
        codeDispatch("error", "Ошибка", "Нет данных");
        console.log("err", err);
      });

    setDoctorData(list);
  };

  const handleEdit = (dataTime, id, coupon_id) => {
    handleOpen(true);
    setDataTime(dataTime);
    setItemId(id);
    setCouponId(coupon_id);
  };

  const handleDelete = (id) => {
    deleteRequest(`clinic/doctor_schedule/${id}/delete/`).then((res) => {
      handleSchedule({});
      dispatch({
        type: SHOW_DIALOG,
        payload: {
          type: "success",
          status: true,
          message: !mobile ? <h2>{res.result}</h2> : <h5>{res.result}</h5>,
        },
      });
    });
  };

  const handleClick = () => {
    const params = {
      id: itemId,
      id_coupon: couponId,
      date: moment(calendarValue).format("YYYY-MM-DD"),
      time: moment(calendarValue).format("HH:mm"),
    };

    postRequest("clinic/doctor_schedule/editing_doctor_schedule/", params).then(
      (res) => {
        handleSchedule({});
        dispatch({
          type: SHOW_DIALOG,
          payload: {
            type: "success",
            status: true,
            message: !mobile ? (
              <h2>{res.data.result}</h2>
            ) : (
              <h5>{res.data.result}</h5>
            ),
          },
        });
        handleCloseModal();
      }
    );
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpen = (current) => {
    setOpenModal(true);
  };
  const [calendarValue, setCalendarValue] = useState("");
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();

  const handleSchedule = (e, page) => {
    setDoctor(e.value || doctor);
    let url = `clinic/doctor_schedule/?clinic=${clinicId}&doctor=${
      doctor || e.value
    }&speciality=${specialtyId}`;

    if (page) {
      url += `&page=${page}`;
    }
    dispatch({
      type: SET_SHOW_LOADER,
      payload: true,
    });
    getRequest(url)
      .then((res) => {
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
        setDataTable(res.data.results);
        setTotal(res.data.count);
        setNextUrl(res.data.next);
      })
      .catch((err) => {
        codeDispatch("error", "Ошибка", errorProcessing(err));
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
      });
  };

  const onChangePagination = (current) => {
    setTestCurrent(current);
    handleSchedule({}, current);
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <div className="text-center">
            <TextField
              id="datetime-local"
              type="datetime-local"
              defaultValue={dataTime}
              inputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                console.log("e.target.value", e.target.value);
                setCalendarValue(e.target.value);
              }}
            />
            <br />
            <br />
            <NewButton
              variant="contained"
              color="primary"
              onClick={() => handleCloseModal()}
            >
              Отменить
            </NewButton>{" "}
            <NewButton
              variant="contained"
              color="secondary"
              onClick={() => handleClick()}
            >
              OK
            </NewButton>
          </div>
        </div>
      </Modal>

      <h3>Добавление расписания врачей:</h3>
      <СustomFileUpload style={{ display: "inline-block", marginRight: 10 }}>
        <input
          type="file"
          accept=".xls, .xlsx"
          onChange={(e) => handleAttachment(e.target)}
        />
      </СustomFileUpload>

      <Span className="mb-2">{fileName}</Span>
      {fileName && (
        <Clear onClick={handleClose} className="ml-2">
          x
        </Clear>
      )}
      <p>
        <NewButton disabled={!fileName} onClick={handleAddFile}>
          Отправить
        </NewButton>
      </p>
      <hr />
      <NewButton onClick={downloadTemplate}>Скачать шаблон</NewButton>
      <hr />

      <Row className="mt-3 mb-3">
        <Col lg={6} className="mt-4">
          <h5>Выберите специальность врача:</h5>
          <Select
            placeholder="Выберите специальность..."
            isLoading={isLoadingClinic}
            options={specialtyData}
            onChange={(e) => handleDoctor(e.value)}
          />
        </Col>
      </Row>
      <br />
      {specialtyId && (
        <Row>
          <Col lg={6}>
            <h5>Выберите ФИО врача:</h5>
            <Select
              key={`select_key__${specialtyId}`}
              placeholder="Выберите врача..."
              isLoading={isLoadingDoctor}
              options={doctorData}
              onChange={(e) => handleSchedule(e, null)}
            />
          </Col>
        </Row>
      )}
      <br />
      {dataTable && (
        <Table>
          <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
            <TableRow>
              <TableCell align="left" colSpan={4}>
                Талон
              </TableCell>
              <TableCell align="left" colSpan={4}>
                Дата/Время
              </TableCell>
              <TableCell align="left" colSpan={4} />
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>
            {dataTable.map(({ id, coupon_id, datetime_from }) => {
              return (
                <TableRow columnNumber={coupon_id} key={id}>
                  <TableCell>{coupon_id}</TableCell>
                  <TableCell>
                    {moment(datetime_from).format("DD.MM.YY HH:mm")}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleEdit(datetime_from, id, coupon_id)}
                    >
                      Редактировать
                    </Button>{" "}
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDelete(id)}
                    >
                      Удалить
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      {total && total > 10 ? (
        <Col xs={12}>
          <Pagination
            showSizeChanger
            className="text-center mt-3 mb-4"
            defaultPageSize={10}
            defaultCurrent={1}
            current={testCurrent}
            onChange={onChangePagination}
            total={total}
            locale={localeInfo}
          />
        </Col>
      ) : null}
    </>
  );
};

export default AddSchedule;
