import styled from "styled-components";
import { Element } from "react-scroll";
import { Button, Input } from "../../../../../common/Components";
import { ReactComponent as Delete } from "../../../../../assets/close-light.svg";

export const Span = styled.span`
  display: inline-block;
  width: 200px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: sub;
`;

export const MessageContainer = styled(Element)`
  height: ${(props) => `calc(100vh - ${props.mobile ? 144 : 210}px)`};
  /* margin-bottom: 64px; */
  overflow: auto;
  /* margin-top: 80px; */
  position: fixed;
  top: 75px;
  padding: 20px 20px 0;
  width: 100%;
  box-sizing: border-box;
  left: 0;
`;

export const СustomFileUpload = styled.label`
  display: block;
  margin: 0 0 20px;

  &::before {
    content: "Загрузить файл";
    display: inline-block;
    color: #fff;
    border: 1px solid #6574cf;
    background-color: #6574cf;
    border-radius: 6px;
    padding: 8px 12px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 10pt;
  }

  &:hover::before,
  &:active::before {
    color: #6574cf;
    background-color: #fff;
  }

  & input {
    display: none;
  }
`;

export const UploadFile = styled.label`
  position: absolute;
  width: 15px;
  height: 15px;
  top: 14px;
  left: 18px;
  color: #6574cf;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  border: 1px solid #6574cf;
  background-color: #d0d0d0;
  border-radius: 50%;
`;

export const NewInput = styled(Input)`
  margin: 0 0 10px;
`;

export const Preview = styled.span`
  display: inline-block;
  width: 200px;
  height: 200px;
  border: 1px solid #e4e9f2;
  border-radius: 6px;
  background-image: url(${(props) => props?.url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const BoxImg = styled.div`
  display: inline-block;
  position: relative;
  margin: 0 0 20px;
`;

export const UpFile = styled.div`
  display: inline-block;
  width: 100%;
  text-align: left;
`;

export const DeleteButton = styled(Delete)`
  position: absolute;
  right: -10px;
  top: -10px;
  border: 1px solid #f00;
  padding: 4px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-sizing: content-box;

  & path {
    fill: #f00;
  }

  &:hover {
    background-color: #f00;

    & path {
      fill: #fff;
    }
  }
`;

export const NewButton = styled(Button)`
  width: auto !important;
  margin: 0 0 10px;
`;

export const BtnGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15px;
  align-items: center;
`;

export const ImgViewing = styled.div`
  display: inline-block;
  width: 100%;
  overflow: auto;
  max-height: calc(100vh - 165px) !important;
`;
