import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import LoaderSpinner from "react-loader-spinner";
import { AppContext } from "../../../../App";
import { postRequest } from "../../../../base/Requests";
import { ReactComponent as Notification } from "../../../../assets/bell.svg";
import { ReactComponent as Arrow } from "../../../../assets/arrow.svg";
import avatarIcon from "../../../../assets/default_profile.png";
import { navigate } from "hookrouter";
import { Button } from "../../../../common/Components";
import {
  AUTHORIZE,
  SET_ACCESS_TOKEN,
  SHOW_DIALOG,
} from "../../../../AppReduser";
import { Col, Row } from "react-bootstrap";
import {
  Avatar,
  Color,
  DropMenu,
  Name,
  NotificationIcon,
  OpenOptions,
  Title,
  UserLogo,
  Wrapper,
  WrapperNotification,
  WrapperUser,
} from "./styleAdminUser";
import { deleteCookie } from "../../../../module/deleteCookie/deleteCookie";

const AdminUser = () => {
  const { dispatch, state } = useContext(AppContext);
  const [data, setDate] = useState(
    JSON.parse(localStorage.getItem("userHeader"))
  );
  const [visibleMessage, setVisibleMessage] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    setDate(JSON.parse(localStorage.getItem("userHeader")));
  }, [state?.userPhoto]);

  const handleBtn = () => {
    postRequest("user/log_out/", { name: 1 })
      .then((res) => {
        if (+res.status === 200) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("role");
          localStorage.setItem("menu", false);
          dispatch({
            type: AUTHORIZE,
            payload: null,
          });
          dispatch({
            type: SET_ACCESS_TOKEN,
            payload: null,
          });
          dispatch({
            type: SHOW_DIALOG,
            payload: {
              status: false,
            },
          });
          deleteCookie();
          navigate("/sign-in/", true);
        }
      })
      .catch((err) =>
        dispatch({
          type: SHOW_DIALOG,
          payload: {
            status: true,
            type: "error",
            message: (
              <>
                <h1>Ошибка !</h1>
                <p>Вы остались в системе</p>
              </>
            ),
          },
        })
      );
  };

  const options = [
    {
      id: 1,
      text: "Редактировать",
      click() {
        navigate(`/editing/`, true);
        setDropdownOpen(false);
      },
    },
    {
      id: 2,
      text: "Выйти",
      click() {
        setDropdownOpen(false);
        dispatch({
          type: SHOW_DIALOG,
          payload: {
            status: true,
            message: (
              <>
                <h1>Выход из учетной записи</h1>
                <p>Вы действительно желаете выйти со своей учетной записи?</p>
              </>
            ),
            customButtons: (
              <Row className="w-100">
                <Col sm={6}>
                  <Button
                    onClick={handleBtn}
                    variant="primary"
                    style={{ marginBottom: "15px" }}
                  >
                    Да
                  </Button>
                </Col>
                <Col sm={6}>
                  <Button
                    onClick={() => {
                      dispatch({
                        type: SHOW_DIALOG,
                        payload: {
                          status: false,
                        },
                      });
                    }}
                  >
                    Отмена
                  </Button>
                </Col>
                <br />
              </Row>
            ),
          },
        });
      },
    },
  ];

  // if (state?.role === "patient") {
  //   options.unshift({
  //     id: 0,
  //     text: "Карты/Добавить карту",
  //     click() {
  //       navigate(`/card/`, true);
  //       setDropdownOpen(false);
  //     },
  //   });
  // }

  const optionsList = (options) => {
    return options.map((item) => {
      return (
        <li onClick={item.click} key={item.id}>
          {item.text}
        </li>
      );
    });
  };

  return (
    <>
      <DropMenu
        onMouseEnter={() => setDropdownOpen(true)}
        onMouseLeave={() => setDropdownOpen(false)}
        isOpen={dropdownOpen}
      >
        {optionsList(options)}
      </DropMenu>

      {data ? (
        <Wrapper>
          {visibleMessage && (
            <>
              <WrapperNotification>
                <NotificationIcon />
                <span />
              </WrapperNotification>
            </>
          )}
          <WrapperUser onClick={() => setDropdownOpen((e) => !e)}>
            <UserLogo>
              <Color>
                <Avatar
                  src={data.hasOwnProperty("photo") ? data.photo : avatarIcon}
                  alt="avatar"
                />
              </Color>
            </UserLogo>
            <Title>
              <Name>
                {data.hasOwnProperty("full_name")
                  ? data.full_name
                  : `${data.first_name} ${data.last_name}`}
              </Name>
            </Title>
            <OpenOptions />
          </WrapperUser>
        </Wrapper>
      ) : (
        <div className="text-right">
          <LoaderSpinner
            type="TailSpin"
            color="#6574cf"
            height={20}
            width={20}
            secondaryColor="gray"
          />
        </div>
      )}
    </>
  );
};

export default AdminUser;
