import styled from "styled-components";

export const AdminPaper = styled.div`
  background-color: #fafbfc;
  position: relative;
  min-height: 100vh;
`;

export const AdminLayout = styled.div`
  @media (max-width: 991px) {
    padding: 0;
  };
`;

// header

export const HeaderLayout = styled.header`
  height: 70px;
  /*width: calc(100% - 40px);*/
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid #d8d8d8;
  position: sticky;
  top: 0;
  z-index: 999;
`;

// navigation

export const NavigationLayout = styled.div`
  width: 80px;
  background-color: white;
  position: fixed;
  height: 100vh; //170px  //calc(100vh - 120px)
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 100px;
`;

// messanger

export const MessangerLayout = styled.div`
  margin-left: 50px;
  margin-top: 50px;
`;

export const ChatBody = styled.div`
  background-color: white;
  min-height: 500px;
  height: 700px;
  width: 1500px;
  border: 1px solid #ebedf8;
  border-radius: 5px;
  display: flex;
`;

export const ChatSearch = styled.div`
  height: 70px;
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid #ebedf8;

  & input {
    width: 80%;
    height: 30px;
    font-size: 16px;
    border: none;
  }
`;

export const Messageholder = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  border-bottom: 1px solid #ebedf8;
  background-color: ${(props) => (props.selected ? "#fafbfc" : "white")};

  &:hover {
    background-color: #fafbfc;
  }
`;

export const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 5px;
`;

export const HolderInfo = styled.div`
  min-width: 50%;
  margin-right: auto;

  & p {
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const HolderAdditional = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 70px;
`;

export const Counter = styled.p`
  min-width: 6px;
  height: 18px;
  padding: 0 5px;
  line-height: 16px;
  border-radius: 18px;
  border: 1px solid #6574cf;
  background-color: #6574cf;
  color: #fff;
  font-size: 11px;
  font-weight: 700;
`;

// chat components

export const Contacts = styled.div`
  border-right: 1px solid #ebedf8;
  /*width: 350px;*/
  flex: 15%;
  height: 100%;

  @media (max-width: 768px) {
    height: 200px;
    overflow: auto;
  };
`;

export const Chat = styled.div`
  position: relative;
  height: calc(100vh - 70px);
  @media (max-width: 768px) {
    height: 500px;
  }
`;

export const NoMessages = styled.p`
  color: silver;
  /*  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);*/
  flex: 85%;
  padding: 20px;
  align-items: center;
  -webkit-flex: 85%;
  -ms-flex: 85%;
  display: flex;
  justify-content: center;
`;
