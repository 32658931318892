import React, { useEffect, useState } from "react";
import moment from "moment";

const DateTime = ({ format = "HH:mm / DD.MM.YYYY" }) => {
  const [date, setDate] = useState(moment().format(format));

  useEffect(() => {
    const interval = setInterval(updateDate, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const updateDate = () => {
    setDate(moment().format(format));
  };

  return date;
};

export default DateTime;
