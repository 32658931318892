import React, { useContext, useEffect, useState } from "react";
import { A } from "hookrouter";
import { getRequest } from "../../../../../base/Requests";
import { SET_SHOW_LOADER, SHOW_DIALOG } from "../../../../../AppReduser";
import { AppContext } from "../../../../../App";
import { CardInsurance, NewBackArrow } from "./InsuranceDetailsStyle";
import PropTypes from "prop-types";

const InsuranceDetails = (props) => {
  const { setHeaderData, id, mobile } = props;
  const patch = window.location.pathname;
  const [data, setData] = useState(null);
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    dispatch({
      type: SET_SHOW_LOADER,
      payload: true,
    });
    const arr = patch.split("/");
    getRequest(`insurance/me/${id}/`)
      .then((res) => {
        setData(res.data);
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_SHOW_LOADER,
          payload: false,
        });
        dispatch({
          type: SHOW_DIALOG,
          payload: {
            status: true,
            type: "error",
            message: (
              <>
                <h1>Ошибка</h1>
                <br />
                <p>Нет данных</p>
              </>
            ),
          },
        });
        console.log("err", err);
      });

    setHeaderData(
      <h1>
        <A href="/insurance">
          <NewBackArrow />
        </A>
        Детали страховки
      </h1>
    );
  }, []);

  return (
    <>
      {data && (
        <CardInsurance className={!mobile ? "w-50" : ""}>
          {data.company.company && (
            <p>Страховая компания: {data.company.company}</p>
          )}

          {data.number && <p>Номер страховки: {data.number}</p>}

          {data.valid_from && <p>Дата начала страховки: {data.valid_from}</p>}

          {data.valid_until && (
            <p>Дата окончания страховки: {data.valid_until}</p>
          )}
          {/*  <p>Доступная страховая сумма: </p>*/}

          {data.is_active && (
            <p>Статус: {data.is_active ? "активна" : "не активна"}</p>
          )}

          {data.visits &&
            data.visits.map((e, index) => {
              return (
                <p key={index}>
                  {e.specialty.length > 1
                    ? `${e.specialty.join(", ")}: ${e.count}`
                    : `${e.specialty}: ${e.count}`}
                </p>
              );
            })}
        </CardInsurance>
      )}
    </>
  );
};

InsuranceDetails.propTypes = {
  setHeaderData: PropTypes.func,
  mobile: PropTypes.bool,
  id: PropTypes.number,
};

export default InsuranceDetails;
